 export const ApplicationName = 'DistributionActivite.Website';

 export const ReturnUrlType = 'returnUrl';

 export const QueryParameterNames = {
   ReturnUrl: ReturnUrlType,
  // Message: 'message'
 };

  export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    //Logout: 'logout',
    //LoggedOut: 'logged-out'
  };

  export const LoginActions = {
    Login: 'login',
   LoginCallback: 'login-callback',
  //  LoginFailed: 'login-failed',
  //  Register: 'register'
  };

  let applicationPaths: ApplicationPathsType = {
    DefaultLoginRedirectPath: '/availability',
    ApiAuthorizationClientConfigurationUrl: `/_configuration/${ApplicationName}`,
  // ApiAuthorizationSignupUrl: `/_configuration/signup`,
    Login: `${LoginActions.Login}`,
 // LoginFailed: `authentication/${LoginActions.LoginFailed}`,
  // LoginCallback: `authentication/${LoginActions.LoginCallback}`,
    Unauthorized: `unauthorized`,
  // Register: `authentication/${LoginActions.Register}`,
   // LogOut: `authentication/${LogoutActions.Logout}`,
   //LoggedOut: `authentication/${LogoutActions.LoggedOut}`,
    LogOutCallback: `authentication/${LogoutActions.LogoutCallback}`,
    LoginPathComponents: [],
    UnauthorizedPathComponents: [],
 //   LoginFailedPathComponents: [],
 //   LoginCallbackPathComponents: [],
 //   RegisterPathComponents: [],
 //   LogOutPathComponents: [],
 //   LoggedOutPathComponents: [],
 //   LogOutCallbackPathComponents: [],
  };

  applicationPaths = {
    ...applicationPaths,
    LoginPathComponents: applicationPaths.Login.split('/'),
    UnauthorizedPathComponents: applicationPaths.Unauthorized.split('/'),
 //   LoginFailedPathComponents: applicationPaths.LoginFailed.split('/'),
 //   RegisterPathComponents: applicationPaths.Register.split('/'),
 //   LogOutPathComponents: applicationPaths.LogOut.split('/'),
 //   LoggedOutPathComponents: applicationPaths.LoggedOut.split('/'),
 //   LogOutCallbackPathComponents: applicationPaths.LogOutCallback.split('/')
  };

 interface ApplicationPathsType {
   readonly DefaultLoginRedirectPath: string;
   readonly ApiAuthorizationClientConfigurationUrl: string;
 //   readonly ApiAuthorizationSignupUrl: string;
    readonly Login: string;
 //   readonly LoginFailed: string;
  // readonly LoginCallback: string;
 //   readonly Register: string;
 //   readonly LogOut: string;
 //   readonly LoggedOut: string;
    readonly LogOutCallback: string;
    readonly Unauthorized: string;
    readonly LoginPathComponents: string [];
    readonly UnauthorizedPathComponents: string [];
 //   readonly LoginFailedPathComponents: string [];
 //   readonly LoginCallbackPathComponents: string [];
 //   readonly RegisterPathComponents: string [];
 //   readonly LogOutPathComponents: string [];
 //   readonly LoggedOutPathComponents: string [];
 //   readonly LogOutCallbackPathComponents: string [];
  }

  export const ApplicationPaths: ApplicationPathsType = applicationPaths;


