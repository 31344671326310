import { ContactInfo } from './contact-info';
import { JobType } from './job-type.model';
import { QuestionOption } from './question';

export class InterventionRequest {

  jobId: number;
  answers: number[];

   constructor(public name, public contact: ContactInfo, public partners: number[], jobType: JobType, options: QuestionOption[]) {
     //this.jobId = jobType.subTypes[0].id;
     this.jobId = jobType.id;
     this.answers = options.map(o => o.id);
   }
}
