import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthorizeGuard } from '@shared/guards/authorize.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { SettingsComponent } from './settings/settings.component';
import { AvailabilityComponent } from './availability/availability.component';
import { InterventionsComponent } from './interventions/interventions.component';
import { HistoryComponent } from './history/history.component';
import { PlanningComponent } from './planning/planning.component';



const routes: Routes = [
  {
    path: '',
    component: AvailabilityComponent,
   canActivate: [AuthorizeGuard],
   canActivateChild: [NgxPermissionsGuard],
    children: [
      {
        path: 'settings',
        component: SettingsComponent,
        data: {
          permissions: {
            only: 'RecevoirInterventionCommanditaire',
           redirectTo: 'planning'
          }
        }
      },
      {
        path: 'interventions',
        component: InterventionsComponent,
        data: {
          permissions: {
            only: 'RecevoirIntervention'
          }
        }
      },
      {
        path: 'history',
        component: HistoryComponent,
        data: {
          permissions: {
            only: 'RecevoirIntervention'
          }
        }
      },
      {
        path: 'planning',
        component: PlanningComponent,
        data: {
          permissions: {
            only: 'RecevoirIntervention'
          }
        }
      },
      { path: '', redirectTo: 'interventions', pathMatch: 'full' },
       { path: '**', component: SettingsComponent }
    ]
  },

  // admin routes
  {
    path: 'admin/:id',
    component: AvailabilityComponent,
    canActivate: [AuthorizeGuard],
    canActivateChild: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'AdminDistribution'
      }
    },
    children: [
      {
        path: 'settings',
        component: SettingsComponent,
        data: {
          permissions: {
            only: 'RecevoirInterventionCommanditaire',
            redirectTo: 'planning'
          }
        }
      },
      {
        path: 'interventions',
        component: InterventionsComponent,
        data: {
          permissions: {
            only: 'RecevoirIntervention'
          }
        }
      },
      {
        path: 'history',
        component: HistoryComponent,
        data: {
          permissions: {
            only: 'RecevoirIntervention'
          }
        }
      },
      {
        path: 'planning',
        component: PlanningComponent,
        data: {
          permissions: {
            only: 'RecevoirIntervention'
          }
        }
      },
    // {path: 'home' , component:HomeComponent},
      { path: '', redirectTo: 'interventions', pathMatch: 'full' } ,
       { path: '**', component: SettingsComponent }
    ]
  },





];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AvailabilityRoutingModule { }
