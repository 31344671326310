
import { UrlService } from './../../services/url.service';
import { Router } from '@angular/router';
import {  AuthenticatedResult,
  OidcClientNotification,
  OidcSecurityService,
  OpenIdConfiguration,
  UserDataResult, } from 'angular-auth-oidc-client';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, startWith, take } from 'rxjs/operators';
import { AuthorizeService } from '@core/services';
import { ApplicationPaths, QueryParameterNames } from '../../api-authorization.constants';

@Component({
  selector: 'app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.scss']
})
export class LoginMenuComponent implements OnInit {
  // public isAuthenticated: Observable<boolean>;
  // public userName: Observable<string>;

  // constructor(private authorizeService: AuthorizeService) { }

  // ngOnInit() {
  //   this.isAuthenticated = this.authorizeService.isAuthenticated();
  //   this.userName = this.authorizeService.getUser().pipe(map(u => u && u.email));
  // }

  public isAuthenticated: Observable<boolean>;
  public userName$: Observable<string>;
  public profileUrl: Observable<string>;

  constructor(public oidcSecurityService: OidcSecurityService, private router: Router, private urlService: UrlService) { }

  ngOnInit() {
    this.profileUrl = this.urlService.getProfileUrl().pipe(startWith('#'));


    this.isAuthenticated = this.oidcSecurityService.isAuthenticated$.pipe(map((res => res.isAuthenticated)));

    this.userName$ = this.oidcSecurityService.userData$.pipe(map(u => {
      // do that to prevent null userData on refresh for some reason
      if (!u.userData) {
        u = {
          userData: this.oidcSecurityService.getUserData(),
          allUserData: [{
            configId: this.oidcSecurityService.getConfiguration().configId,
            userData: this.oidcSecurityService.getUserData(),

          }]

        } as UserDataResult;
        console.log(this.isAuthenticated)
      }
      return u && u.userData && u.userData.email
    }));

  }

  logout() {
    this.oidcSecurityService.logoff();
  }


  login() {
    this.router.navigate(ApplicationPaths.LoginPathComponents, {
      queryParams: {
        [QueryParameterNames.ReturnUrl]: this.router.url.split('?')[0]
      }
    });
  }

  register() {
    this.urlService.getSignupUrl().pipe(
      take(1)
    ).subscribe(signupUrl => {
      // get valid authorize url
      this.oidcSecurityService.authorize(null, {
        urlHandler: (authUrl: string) => {
          if (typeof authUrl !== 'undefined' && authUrl) {
            const encodedString = btoa(authUrl);
            window.location.href = `${signupUrl}?ssoLoginUrl=${encodedString}&style_id=tt_elm`;
          }
        }
      });
    });
  }

 urlHandler(url: string){
   const autorizeUrl = url;
 }
}


