import { Component, OnInit, Input } from '@angular/core';
import { Intervention } from '../models/intervention';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-history-info-modal',
  templateUrl: './history-info-modal.component.html',
  styleUrls: ['./history-info-modal.component.scss']
})
export class HistoryInfoModalComponent implements OnInit {

  @Input() intervention: Intervention;

  public onProcessed: Subject<Intervention>;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onProcessed = new Subject();
  }
}
