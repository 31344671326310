<!-- <div class="center">
  <p>{{ message | async }}</p>
</div> -->



<main role="main" class="home" style="margin:20px;font-size:1.1em;">
  Vous êtes maintenant déconnecté.<br />
  Merci de cliquer sur le bouton "Se connecter" pour vous connecter.
</main>




