<div class="container-fluid">
  <div class="my-4">
    <app-nav-menu></app-nav-menu>

  </div>
  <!-- <ng-container *ngIf="!isAdminView || (setOrganization$ | async)"> -->
  <ng-container *ngIf="setOrganization$ | async">
    <router-outlet></router-outlet>
  </ng-container>
</div>
