import { Component, OnInit, Input } from '@angular/core';
import { ContactInfo } from '../models/contact-info';
import { Subject, InteropObservable } from 'rxjs';

@Component({
  selector: 'app-intervention-contact',
  templateUrl: './intervention-contact.component.html',
  styleUrls: ['./intervention-contact.component.scss']
})
export class InterventionContactComponent implements OnInit {

  @Input() contact: ContactInfo;

  public onProcessed: Subject<ContactInfo>;

  constructor() { }

  ngOnInit() {
    this.onProcessed = new Subject();
  }

  accept() {
    this.onProcessed.next(this.contact);
  }

}
