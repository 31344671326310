<div class="card">
  <p class="card-subtitle mb-2 text-muted pl-3">Fiche d'intervention n° <span
      class="color-title">{{intervention.id}}</span></p>
  <div class="card-body">
    <div class="row" style="height: auto;">
      <div *ngIf="intervention.commanditaireIntervention == null then thenBlock else elseBlock"></div>
      <ng-template #thenBlock>
        <app-intervention-contact [contact]="intervention.contact" class="col-sm-6"></app-intervention-contact>
      </ng-template>
      <ng-template #elseBlock>
        <app-intervention-commanditaire [commanditaire]="intervention.commanditaireIntervention" class="col-sm-6">
        </app-intervention-commanditaire>
      </ng-template>
      <app-intervention-info [intervention]="intervention" class="col-sm-6"></app-intervention-info>
    </div>
    <div class="row">
      <div *ngIf="intervention.commanditaireIntervention == null then thenBlockComment else elseBlockComment"></div>
      <ng-template #thenBlockComment>
        <div class="col-12 div-comments py-5">
          <p class="card-text">Commentaire :</p>
          <textarea name="commentary" id="commentary">{{intervention.contact.comments}}</textarea>
        </div>
        <div class="col-12 div-buttons py-3">-
          <div *ngIf="!intervention.isPlanned && !intervention.isProcessed">
            <button type="button" class="btn btn-primary col-sm-3 float-left"
              (click)="openDetail(intervention)">Accepter</button>
            <button type="button" class="btn btn-light col-sm-4 float-right"
              (click)="openRefusal(intervention)">Refuser</button>
          </div>
          <div *ngIf="intervention.isPlanned && !intervention.isProcessed">
            <button type="button" class="btn btn-success float-right" (click)="process()">Intervention réalisée</button>
          </div>
          <!-- <div class="row">
                      <button type="button" class="btn btn-primary float-left"> GO </button>
                      <span>Télécharger la fiche d'intervention</span>
                  </div> -->
        </div>
      </ng-template>
      <ng-template #elseBlockComment>
        <div class="col-12 card-option pt-2"
          *ngIf="intervention.commanditaireIntervention.typeClient == 'professionnel'">
          Client professionnel
        </div>
        <div class="col-12 card-option pt-2" *ngIf="intervention.commanditaireIntervention.hasToInstallThermostat">
          Installation d'un thermostat
        </div>
        <div class="col-12 div-comments py-5">
          <p class="card-text">Commentaire :</p>
          <textarea name="commentary" id="commentary">{{intervention.commanditaireIntervention.commentaires}}</textarea>
        </div>
        <div class="col-12 div-buttons py-3">
          <div *ngIf="!intervention.isPlanned && !intervention.isProcessed">
            <button type="button" class="btn btn-primary col-sm-3 float-left"
              (click)="takeInterventionCommanditaire(intervention.commanditaireIntervention.idIntervention)">Accepter</button>
            <button type="button" class="btn btn-light col-sm-4 float-right"
              (click)="openRefusal(intervention)">Refuser</button>
          </div>
          <div *ngIf="intervention.isPlanned && !intervention.isProcessed">
            <button type="button" class="btn btn-success col-sm-4"
              (click)="openConfirm(reasonsTemplate, true, false)">Réalisée</button>
            <button type="button" class="btn btn-primary col-sm-4"
              (click)="openConfirm(reasonsTemplate, true, true)">Réalisée avec anomalie</button>
            <button type="button" class="btn btn-light col-sm-4"
              (click)="openConfirm(reasonsTemplate, false, false)">Non réalisée</button>
          </div>
          <!-- <div class="row">
                      <button type="button" class="btn btn-primary float-left"> GO </button>
                      <span>Télécharger la fiche d'intervention</span>
                  </div> -->
        </div>
        <div class="col-12 div-buttons py-3" *ngIf="intervention.commanditaireIntervention.hasMaintenanceCertificate">
          <button class="btn btn-primary col-sm-4" (click)="downloadMaintenanceCertificate()">Télécharger l'attestation
            d'entretien</button>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #reasonsTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Sélectionner un motif</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="confirmModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #confirmForm="ngForm">
      <div class="form-group" [hidden]="hideSelectReason">
        <select class="custom-select custom-select-lg" id="inputReason" name="selectedReason"
          [(ngModel)]="selectedReason" required>
          <option *ngFor="let reason of reasons$ | async" [ngValue]="reason.idMotif">{{reason.libelle}}</option>
        </select>
      </div>
      <div class="form-group row"
        *ngIf="intervention.commanditaireIntervention?.hasToInstallThermostat && interventionIsRealized">
        <label for="hasInstalledThermostat" class="col-form-label col-form-label-lg">Avez-vous pu installer un
          thermostat au client ?</label>
        <div class="form-control">
          <label class="radio-inline">
            <input style="margin-left: 2rem;" name="hasInstalledThermostat" type="radio" value="OK"
              [(ngModel)]="hasInstalledThermostat" required> Oui
          </label>
          <label class="radio-inline">
            <input style="margin-left: 2rem;" name="hasInstalledThermostat" type="radio" value="KO"
              [(ngModel)]="hasInstalledThermostat" required> Non
          </label>
        </div>
      </div>
      <div *ngIf="hasInstalledThermostat == 'OK' && interventionIsRealized" class="form-group row">
        <label for="typeThermostat" class="col-form-label col-form-label-lg">Quel est le type de thermostat installé
          ?</label>
        <div class="form-control">
          <label class="radio-inline">
            <input style="margin-left: 2rem;" name="typeThermostat" type="radio" value="01" [(ngModel)]="typeThermostat"
              required> Filaire
          </label>
          <label class="radio-inline">
            <input style="margin-left: 2rem;" name="typeThermostat" type="radio" value="02" [(ngModel)]="typeThermostat"
              required> Non filaire
          </label>
        </div>
      </div>
      <div *ngIf="hasInstalledThermostat == 'KO'" class="form-group row">
        <label for="inputReasonNonInstallation" class="col-sm-3 col-form-label">Sélectionnez une raison</label>
        <div class="col-sm-9">
          <select class="custom-select custom-select-lg" id="inputReasonNonInstallation"
            name="selectedReasonNonInstallation" [(ngModel)]="selectedReasonNonInstallation" required>
            <option *ngFor="let reason of listReasonNonInstallation" [value]="reason">{{reason}}</option>
          </select>
        </div>
      </div>
      <div *ngIf="intervention.commanditaireIntervention?.typeClient == 'professionnel'" class="form-group row">
        <label for="usageMixte" class="col-form-label col-form-label-lg">Une partie du bâtiment est-elle composée
          d'habitations ?</label>
        <div class="form-control">
          <label class="radio-inline">
            <input style="margin-left: 2rem;" name="usageMixte" type="radio" [(ngModel)]="usageMixte" [value]="true"
              required> Oui
          </label>
          <label class="radio-inline">
            <input style="margin-left: 2rem;" name="usageMixte" type="radio" [(ngModel)]="usageMixte" [value]="false"
              required> Non
          </label>
        </div>
      </div>
      <div *ngIf="intervention.commanditaireIntervention?.typeClient == 'professionnel' && usageMixte == true"
        class="form-group row">
        <label for="majoriteHabitation" class="col-form-label col-form-label-lg">Quelle est la proportion d'habitations
          dans le bâtiment ?</label>
        <div class="form-control">
          <label class="radio-inline">
            <input style="margin-left: 2rem;" name="majoriteHabitation" type="radio" [(ngModel)]="majoriteHabitation"
              [value]="true" required> plus de 50%
          </label>
          <label class="radio-inline">
            <input style="margin-left: 2rem;" name="majoriteHabitation" type="radio" [(ngModel)]="majoriteHabitation"
              [value]="false" required> moins de 50%
          </label>
        </div>
      </div>
      <div *ngIf="intervention.commanditaireIntervention?.abreviationCommanditaire == 'hwa' && selectedReason != 4"
        class="form-group row">
        <label for="remplacementRequis" class="col-form-label col-form-label-lg">L'appareil doit-il être remplacé
          ?</label>
        <div class="form-control">
          <label class="radio-inline">
            <input style="margin-left: 2rem;" name="remplacementRequis" type="radio" [(ngModel)]="remplacementRequis"
              [value]="true" required> Oui
          </label>
          <label class="radio-inline">
            <input style="margin-left: 2rem;" name="remplacementRequis" type="radio" [(ngModel)]="remplacementRequis"
              [value]="false" required> Non
          </label>
        </div>
      </div>
      <div *ngIf="interventionIsRealized" class="form-group row">
        <label for="fileUpload" class="col-form-label col-form-label-lg">Joindre l'attestation d'entretien <small>(au
            format pdf)</small> :</label>
        <div class="form-control">
          <!-- TEMP : required or not ? -->
          <input (change)="onFileChange($event)" type="file" id="fileUpload" name="file" [(ngModel)]="file">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" [disabled]="!confirmForm.valid || selectedReason === 0"
      (click)="processCommanditaire()">Valider</button>
  </div>
</ng-template>

<!-- <p>paragraphe test : {{gender}}</p>
<p>
  <input type="radio" name="gender" [(ngModel)]="gender" [value]="true"> True
  <input type="radio" name="gender" [(ngModel)]="gender" [value]="false"> False8
</p> -->