<div class="panel">
  <div class="section-title">Créer une demande d'intervention</div>
  <div class="section-subtitle">Type d'intervention</div>
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="form-group col-md-6">
        <label for="type">Où est votre problème ?</label>
        <select class="form-control" formControlName="type" (change)="onSelectType()">
          <option [ngValue]="null" disabled>Choisir</option>
          <option *ngFor="let jobType of jobTypes$ | async" [ngValue]="jobType">
            {{jobType.name}}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="subtype">Quel est votre besoin ?</label>
        <select class="form-control" formControlName="subType" (change)="onSelectSubType()">
          <option [ngValue]="null" disabled>Choisir</option>
          <option *ngFor="let subType of jobSubTypes" [ngValue]="subType">
            {{subType.name}}
          </option>
        </select>
      </div>
    </div>
  </form>
</div>

