export class SelectedDate {
  date: Date;
  type: DateType;
  saved: boolean;
  hasIntervention: boolean;
}

export enum DateType {
  AM = 'mat',
  PM = 'apr',
  ALL = 'day',
  NONE = 'none'
}
