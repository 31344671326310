<!-- <div class="row">
    <div class="col-3 offset"><small><i>Veuillez sélectionner les demi-journées sur lesquelles vous souhaitez recevoir des interventions de notre part</i></small></div>
</div> -->
<div class="row">
  <div class="col-xl-3">
    <h2>Mes disponibilités</h2>
    <p>Renseignez ici vos créneaux disponibles.</p>
    <p>Vous pouvez enregistrer autant de créneaux que vous le souhaitez, et revenir modifier ces créneaux à n'importe quel moment.</p>
    <p>Attention, si vous supprimez un créneau alors qu'un rendez-vous est déjà planifié, ce dernier devra être assuré.</p>
  </div>
  <div class="col-xl-9">
    <!-- <div class="row">
      <div class="col-lg-5 col-xl-4"><small><i>Veuillez sélectionner les demi-journées sur lesquelles vous souhaitez recevoir des interventions de notre part</i></small></div>
    </div> -->
    <div class="row">
      <div class="col-lg-5 col-xl-4">
        <app-calendar></app-calendar>
      </div>
      <div class="col-lg-7 col-xl-8">
        <app-availability-settings></app-availability-settings>
      </div>
    </div>
  </div>
</div>

