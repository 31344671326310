import { Injectable } from '@angular/core';
import { STEPS } from '../models/workflow.model';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  private workflow = [
    { step: STEPS.job, valid: false },
    { step: STEPS.question, valid: false },
    { step: STEPS.info, valid: false },
    { step: STEPS.partner, valid: false }
  ];

  validateStep(step: string) {
      // If the state is found, set the valid field to true
      const foundStep = this.workflow.find(s => s.step === step);
      if (foundStep !== undefined) {
        foundStep.valid = true;
      }
  }

  resetStep(step: string) {
    // If the state is found, set the valid field to true
    const foundStep = this.workflow.find(s => s.step === step);
    if (foundStep !== undefined) {
      foundStep.valid = false;
    }
  }

  resetSteps() {
      // Reset all the steps in the Workflow to be invalid
      this.workflow.forEach(element => {
          element.valid = false;
      });
  }

  getFirstInvalidStep(step: string): string {
      // If all the previous steps are validated, return blank
      // Otherwise, return the first invalid step
      let redirectToStep = '';
      const foundStep = this.workflow.find(s => !s.valid);
      if (foundStep !== undefined && foundStep.step !== step) {
        redirectToStep = foundStep.step;
      }
      return redirectToStep;
  }
}
