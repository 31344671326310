import { Component, OnInit } from '@angular/core';
import { AvailabilityService } from '../services/availability.service';
import { Observable, of } from 'rxjs';
import { Skill } from '../models/skill';
import { switchMap, map, shareReplay } from 'rxjs/operators';
import { UrlService } from '@core/services';

@Component({
  selector: 'app-skill-list',
  templateUrl: './skill-list.component.html',
  styleUrls: ['./skill-list.component.scss']
})
export class SkillListComponent implements OnInit {

  skills$: Observable<string[]>;
  adminUrl$: Observable<string>;

  constructor(private availabilityService: AvailabilityService, private urlService: UrlService) { }

  ngOnInit(): void {
    this.skills$ = this.availabilityService.getSkills().pipe(shareReplay(1));

    this.adminUrl$ = this.urlService.getCubeUrl().pipe(
      map(url => url + '/Administration')
    );

    this.availabilityService.skillsUpdated.subscribe(_ => this.skills$ = this.availabilityService.getSkills().pipe(shareReplay(1)))
  }

}
