import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { FormDataService } from 'app/interventions/services/form-data.service';
import { TimepickerConfig } from 'ngx-bootstrap/timepicker';
import { AvailabilityService } from '../services/availability.service';
import { Subscription } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Settings } from '../models/settings';


export function getTimepickerConfig(): TimepickerConfig {
  return Object.assign(new TimepickerConfig(), {
    hourStep: 1,
    minuteStep: 30,
    showMeridian: false,
    showSpinners: false,
  });
}

@Component({
  selector: 'app-calendar-settings',
  templateUrl: './calendar-settings.component.html',
  styleUrls: ['./calendar-settings.component.scss'],
  providers: [{ provide: TimepickerConfig, useFactory: getTimepickerConfig }]
})
export class CalendarSettingsComponent implements OnInit {

  formGroup: UntypedFormGroup;
  submitted = false;
  info$: Subscription;

  isSaving = false;

  constructor(private formBuilder: UntypedFormBuilder, private formDataService: FormDataService,
    private availabilityService: AvailabilityService) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      morningStart: [null, [Validators.required]],
      morningEnd: [null, [Validators.required]],
      morningMax: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      afternoonStart:  [null, [Validators.required]],
      afternoonEnd: [null, [Validators.required]],
      afternoonMax: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      blockedDays: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      weekMax: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
    });

    this.info$ = this.availabilityService.getSettings()
      .pipe(
        // map(settings => {
        //   return { ...settings,
        //     morningStart : this.createLocalDate(settings.morningStart),
        //     morningEnd: this.createLocalDate(settings.morningEnd),
        //     afternoonStart: this.createLocalDate(settings.afternoonStart),
        //     afternoonEnd: this.createLocalDate(settings.afternoonEnd),
        // } as Settings;
     // }),
        tap(settings => {
          this.formGroup.patchValue(settings);

          // Correct bug hours
          this.formGroup.patchValue({morningStart: this.removeLastDateChar(settings.morningStart)});
          this.formGroup.patchValue({morningEnd: this.removeLastDateChar(settings.morningEnd)});
          this.formGroup.patchValue({afternoonStart: this.removeLastDateChar(settings.afternoonStart)});
          this.formGroup.patchValue({afternoonEnd: this.removeLastDateChar(settings.afternoonEnd)});
        })
      ).subscribe();

  }

  private removeLastDateChar(date :Date) : string{
   return date?.toString().slice(0, -1);
 // return date..slice(0,1)
  }

  get morningMax() {
    return this.formGroup.get('morningMax');
  }

  get morningStart() {
    return this.formGroup.get('morningStart');
  }

  get morningEnd() {
    return this.formGroup.get('morningEnd');
  }

  get afternoonMax() {
    return this.formGroup.get('afternoonMax');
  }

  get afternoonStart() {
    return this.formGroup.get('afternoonStart');
  }

  get afternoonEnd() {
    return this.formGroup.get('afternoonEnd');
  }

  get blockedDays() {
    return this.formGroup.get('blockedDays');
  }

  get weekMax() {
    return this.formGroup.get('weekMax');
  }

  onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    const info = { ...this.formGroup.value,
      morningStart : this.createUtcDate(this.formGroup.value.morningStart),
      morningEnd: this.createUtcDate(this.formGroup.value.morningEnd),
      afternoonStart: this.createUtcDate(this.formGroup.value.afternoonStart),
      afternoonEnd: this.createUtcDate(this.formGroup.value.afternoonEnd),
      morningMax: +this.formGroup.value.morningMax,
      afternoonMax: +this.formGroup.value.afternoonMax,
      weekMax: +this.formGroup.value.weekMax,
      blockedDays: +this.formGroup.value.blockedDays
    } as Settings;

    this.isSaving = true;

    this.availabilityService.setSettings(info).subscribe(res => this.isSaving = false);
  }

  private createUtcDate(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
  }

  private createLocalDate(utcDate: string): Date {
    const utc = new Date(utcDate);
    return new Date(utc.getFullYear(), utc.getMonth(), utc.getDate(), utc.getHours(), utc.getMinutes());

  }
}
