import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PartnerService } from '../services/partner.service';
import { map, switchMap, tap, catchError } from 'rxjs/operators';
import { InterventionUpdateReason } from '../models/intervention-answer';
import { UpdateInfo } from '../models/update-info';
import { InterventionToConfirmInfo, Reason } from '../models/intervention-info';
import { of, throwError } from 'rxjs';
//import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-update-reason',
  templateUrl: './update-reason.component.html',
  styleUrls: ['./update-reason.component.scss']
})
export class UpdateReasonComponent implements OnInit {

  guid: string;
  idOrganisation: number;
  info: InterventionToConfirmInfo;

  motifs: Reason[] = [];

  selectedReason: number;
  status = 'En cours ...';

  submitted = false;

  hasInstalledThermostat: string;
  typeThermostat: string;

  listReasonNonInstallation: string[] = [
    'Refus client',
    'Installation impossible',
    'Thermostat hors-service',
    'Thermostat non compatible',
    'Thermostat non transmis au technicien',
    'Thermostat non disponible'
  ]

  selectedReasonNonInstallation: string;

  usageMixte: boolean;
  majoriteHabitation: boolean;

  remplacementRequis: boolean;

  file: File = null;

  constructor(private route: ActivatedRoute, private partnerService: PartnerService) { }

  ngOnInit(): void {
    // this.motifs.push({idMotif: 1, libelle: 'a'} as Reason);
    // this.motifs.push({idMotif: 2, libelle: 'b'} as Reason);

    this.route.queryParamMap.pipe(
      map(p => ({
        guid: p.get('guid'),
        idOrganisation: +p.get('idOrganisation'),
        realized: this.toBoolean(p.get('realised')),
        hasAnomaly: this.toBoolean(p.get('withAnomaly')),
      } as UpdateInfo)),
      tap(info => {
        this.guid = info.guid;
        this.idOrganisation = info.idOrganisation;
      }),
      switchMap(info => this.partnerService.getInterventionInfo(info)),
      catchError(err => {
        this.status = 'Erreur interne';
        return throwError(err);
      })
    )
      .subscribe(data => {
        this.info = data;
        this.usageMixte = data.usageMixte;
        this.majoriteHabitation = data.majoriteHabitation;
        //console.log("voici les données intervention", this.info);
      });
  }

  onSubmit() {
    this.submitted = true;

    const data = {
      guid: this.guid,
      idOrganisation: this.idOrganisation,
      hasInstalledThermostat: this.hasInstalledThermostat,
      typeThermostat: this.typeThermostat,
      selectedReasonNonInstallation: this.selectedReasonNonInstallation,
      usageMixte: this.usageMixte,
      majoriteHabitation: this.majoriteHabitation,
      remplacementRequis: this.remplacementRequis,
    } as InterventionUpdateReason;

    // Check if there is only one reason in listeMotif, if so the select is not present in the page and selectedReason doesn't return anything
    if (this.info.listeMotifs.length > 1) {
      data.idMotif = +this.selectedReason;
    }
    else {
      data.idMotif = this.info.listeMotifs[0].idMotif;
    }

    this.partnerService.updateReason(data).pipe(
      catchError(err => {
        this.status = 'Erreur interne';
        return throwError(err);
      })
    ).subscribe(result => {
      this.status = result;
    });

    if (!!this.file && this.info.realised) {
      let fd = new FormData();
      fd.append("file", this.file);
      fd.append("idIntervention", this.info.idIntervention.toString());

      this.partnerService.uploadMaintenanceCertificate(fd).subscribe();
    }

    this.info = null;
  }

  onFileChange(event) {
    this.file = event.target.files[0];
  }

  private toBoolean = (value: string | number | boolean): boolean =>
    [true, 'true', 'True', 'TRUE', '1', 1].includes(value)
}
