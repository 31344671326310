import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

const GEO_API_GOUV_URL = 'https://geo.api.gouv.fr/'

export class DepartmentGeo {
  nom: string;
  code: string;
  codeRegion: string;
  cities: CityGeo[]
}

export class CityGeo {
  nom: string;
  code: string;
  codeDepartement: string;
  codeRegion: string;
  codesPostaux: string[];
}

@Injectable({
  providedIn: 'root'
})
export class GeoApiService {

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
    })
  };

  constructor(public http: HttpClient) { }

  getDepartments(): Observable<DepartmentGeo[]> {
    return this.http.get<any>(GEO_API_GOUV_URL + 'departements').pipe(
      map(deps => deps.map(dep => {
        return {
          nom: dep.nom,
          code: dep.code,
          codeRegion: dep.codeRegion,
        } as DepartmentGeo
      })
      )
    );
  }

  getCities(): Observable<CityGeo[]> {
    return this.http.get<any>(GEO_API_GOUV_URL + 'communes').pipe(
      map(cities => cities.map(city => {
        return {
          nom: city.nom,
          code: city.code,
          codeDepartement: city.codeDepartement,
          codeRegion: city.codeRegion,
          codesPostaux: city.codesPostaux,
        } as CityGeo
      })
      )
    );
  }
}
