import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JobSelectorComponent } from './job-selector/job-selector.component';
import { InterventionComponent } from './intervention/intervention.component';
import { QuestionComponent } from './question/question.component';
import { WorkflowGuard } from './guards/workflow-guard';
import { InfoComponent } from './info/info.component';
import { PartnerComponent } from './partner/partner.component';
import { InterventionListComponent } from './intervention-list/intervention-list.component';
import { HomeComponent } from './home/home.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthorizeGuard } from '../shared/guards/authorize.guard';
import { UpdateComponent } from './update/update.component';
import { UpdateReasonComponent } from './update-reason/update-reason.component';




const routes: Routes = [
  {
    path: 'intervention/updatereason',
    component: UpdateReasonComponent
  },
  {
    path: 'intervention/update',
    component: UpdateComponent
  },
  { path: 'intervention',
    canActivate: [AuthorizeGuard],
    component: InterventionComponent,
    canActivateChild: [NgxPermissionsGuard],
    children: [
      { path: 'job',
        component: JobSelectorComponent,
        data: {
          permissions: {
            only: 'DemandeIntervention'
          }
        }
      },
      { path: 'question',
        component: QuestionComponent,
        data: {
          permissions: {
            only: 'DemandeIntervention'
          }
        },
        canActivate: [WorkflowGuard]},
      { path: 'info',
        component: InfoComponent,
        data: {
          permissions: {
            only: 'DemandeIntervention'
          }
        },
        canActivate: [WorkflowGuard]},
      { path: 'partner',
        component: PartnerComponent,
        data: {
          permissions: {
            only: 'DemandeIntervention'
          }
        },
        canActivate: [WorkflowGuard]},
      { path: 'list',
        component: InterventionListComponent,
        data: {
          permissions: {
            only: 'DemandeIntervention'
          }
        }
      },
      { path: '',   component: HomeComponent, pathMatch: 'full' },
      { path: '**', component: HomeComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InterventionsRoutingModule { }
