<div class="panel h-100">
  <div class="section-title">Je déclare mes disponibilités</div>
  <div class="mb-3">
    <i>Veuillez sélectionner les demi-journées sur lesquelles vous pouvez intervenir.</i>
  </div>

  <bs-datepicker-inline [hidden]="!(availabilities$ | async)" (bsValueChange)="onValueChange($event)"
    (onShown)="onShown($event)" [minDate]="minDate" [dateCustomClasses]="selectedClass" [datesDisabled]="disabledDates"
    [bsConfig]="{ customTodayClass: 'current-date', useUtc: true }">
  </bs-datepicker-inline>
  
  <!-- calendar loader -->
  <div *ngIf="isCalendarLoading" class="col-12 my-5">
    <mat-spinner style="left: unset; transform: unset;" class="spinner mx-auto"></mat-spinner>
  </div>

  <div class="custom-control custom-checkbox my-3">
    <input #allCheckBox type="checkbox" class="custom-control-input" id="customCheck1" [checked]="selectAllChecked"
      (change)="toggleMonth(allCheckBox.checked)">
    <label class="custom-control-label" for="customCheck1">Tout sélectionner</label>
  </div>
  <div>
    <table class="table table-borderless table-sm">
      <thead>
        <tr>
          <th colspan="3">Légende</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1 clic</td>
          <td><span class="circle custom-selected-date mat"></span></td>
          <td>Créneau matin</td>
        </tr>
        <tr>
          <td>2 clics</td>
          <td><span class="circle custom-selected-date apr"></span></td>
          <td>Créneau après-midi</td>
        </tr>
        <tr>
          <td>3 clics</td>
          <td><span class="circle custom-selected-date day"></span></td>
          <td>Créneau matin + après-midi</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="clearfix">
    <button *ngIf="!isSaving" type="submit" class="btn btn-primary float-right"
      (click)="saveAvailabilities()">Enregistrer</button>
    <button *ngIf="isSaving" type="button" class="btn btn-primary float-right" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Enregistrement...
    </button>
  </div>
</div>