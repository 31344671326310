import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormDataService } from '../services/form-data.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactInfo } from '../models/contact-info';
import { ActivatedRoute, Router } from '@angular/router';
import { InterventionService } from '../services/intervention.service';
import { tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {

  submitted = false;
  formGroup: UntypedFormGroup;
  info$: Subscription;

  constructor(private formBuilder: UntypedFormBuilder, private formDataService: FormDataService,
    private router: Router, private route: ActivatedRoute, private interventionService: InterventionService) { }

  ngOnInit() {

    this.formGroup = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      address:  ['', [Validators.required]],
      address2: [''],
      zip: ['', [Validators.required, Validators.pattern('^[0-9]{5}$')]],
      city:  ['', [Validators.required]],
      phone:  ['', [Validators.required]],
      email:  ['', [Validators.required,  Validators.email]],
      comments: [''],
    });

    const info = this.formDataService.getPersonalInfo();
    if (info === null || info === undefined) {
      this.info$ = this.interventionService.getContactInfo()
      .pipe(
        tap(contact => this.formGroup.patchValue(contact))
      ).subscribe();
    } else {
      this.formGroup.patchValue(info);
    }
  }

  get firstName() {
    return this.formGroup.get('firstName');
  }

  get lastName() {
    return this.formGroup.get('lastName');
  }

  get address() {
    return this.formGroup.get('address');
  }

  get zip() {
    return this.formGroup.get('zip');
  }

  get city() {
    return this.formGroup.get('city');
  }

  get phone() {
    return this.formGroup.get('phone');
  }

  get email() {
    return this.formGroup.get('email');
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }

    const info = this.formGroup.value as ContactInfo;
    this.formDataService.setPersonalInfo(info);

    this.router.navigate(['../partner'], { relativeTo: this.route });
  }

  previousStep() {
    // back to previous form
    this.formDataService.resetSelectedOption();
    this.router.navigate(['../question'], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    if (this.info$ !==  undefined) {
      this.info$.unsubscribe();
    }
  }
}
