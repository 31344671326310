<div class="panel">
  <div class="section-title">Créer une demande d'intervention</div>
<div>
  <p class="legend">{{message}}</p>
</div>
<div *ngIf="partners$ | async as partners">
  <div class="card-deck">
    <div class="card" *ngFor="let partner of partners">
      <div class="card-body">
        <div class="card-title">{{partner.name}}</div>
        <address>
          {{partner.name}}
          {{partner.address}}<br>
          {{partner.postCode}} {{partner.city}}
        </address>
      </div>
    </div>
  </div>
</div>
</div>

<div class="clear-fix mt-1">
  <button class="btn btn-light float-left" (click)="previousStep()" *ngIf="!interventionCreated">Etape précédente</button>
  <!-- <button type="submit" class="btn btn-primary float-right" (click)="createRequest()" [disabled]="interventionCreated">Envoyer la demande</button> -->
  <a *ngIf="interventionCreated" [routerLink]="['/availability/interventions']" class="btn btn-light float-left">Retour</a>
</div>


