import { StorageService } from './core/services/storage.service';
import { Router } from '@angular/router';
import { PermissionService } from '@core/services';
import { PublicEventsService,  OidcSecurityService, EventTypes } from 'angular-auth-oidc-client';
//import { UserService } from './core/services/user.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { filter, switchMap } from 'rxjs/operators';
const RETURNURL_KEY = 'returnurl';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  private storage:Storage

  constructor(private titleService: Title,private eventService: PublicEventsService, private permissionService : PermissionService,
    private oidcSecurityService:OidcSecurityService, private router:Router, private storageService: StorageService){
      this.titleService.setTitle(`elmLeCube.fr – Distribution et suivi d’activités`);
      this.storage = this.storageService.get()
    }




  ngOnInit(): void {
    this.eventService
    .registerForEvents()
    .pipe(
      filter((notification) => notification.type === EventTypes.UserDataChanged),
      switchMap((notification) => {
        console.log(notification);
        return this.permissionService.handlePermissions(notification.value != null)
      })
    )
   //.subscribe((perm) => console.log('permissions: ' + perm));

  this.oidcSecurityService
    .checkAuth()
    .subscribe(
      (response) => {
        if (response.isAuthenticated) {
          this.permissionService.handlePermissions(true).subscribe();
          this.navigateToStoredEndpoint();
        } else {
          let refreshToken = this.oidcSecurityService.getRefreshToken();
          if (!response.isAuthenticated && !!refreshToken) {
            this.oidcSecurityService.forceRefreshSession().subscribe((result) => {
              this.permissionService.handlePermissions(true).subscribe();
            });
          }
        }
      },
      (error) => console.error(error),
      () => this.storage.removeItem(RETURNURL_KEY),
    );

  }
 private navigateToStoredEndpoint() {
   const path = this.storage.getItem(RETURNURL_KEY);

  if (path == null || this.router.url === path) {
    return;
  }

  if (path.toString().includes('/unauthorized')) {
    this.router.navigate(['/']);
  } else {
    this.router.navigate([path]);
  }
  }

  refreshSession() {
    this.oidcSecurityService.forceRefreshSession().subscribe();
  }
}
