<!-- <div class="d-flex justify-content-end">
<ng-container *ngIf="isAuthenticated | async; else signinBlock">
  <a class="btn btn-user d-md-block" tabindex="0" placement="bottom" [popover]="a1" containerClass="popover-custom">
    <span class="optional">{{ userName | async }}</span>
  </a>  -->
<!-- Content for Popover #1 -->
<!-- <ng-template #a1>
         <a [routerLink]='["/authentication/logout"]' class="btn btn-sm btn-deconnect" [state]='{ local: true }'>Se déconnecter</a>
  </ng-template>
</ng-container>
<ng-template #signinBlock>
  <a class="btn btn-register d-none d-sm-none d-md-block" [routerLink]='["/authentication/register"]' tabindex="0">
    S'inscrire
  </a>
  <a class="btn btn-sign-in d-none d-sm-none d-md-block"  [routerLink]='["/authentication/login"]' tabindex="0">
    Se connecter
  </a>
</ng-template>
</div> -->



<div class="d-flex justify-content-end">
  <ng-container *ngIf="isAuthenticated | async; else signinBlock">
    <a class="btn btn-user d-none d-md-block" tabindex="0" placement="bottom" [ngbPopover]="popContent"
       popoverClass="popover-custom">
      <span class="optional">{{ userName$ | async }}</span>
    </a>
    <!-- <a class="btn btn-user d-block d-md-none" tabindex="0" placement="left" [ngbPopover]="popContent"
       popoverClass="popover-custom">
    </a> -->

    <a class="btn btn-user d-block d-md-none" tabindex="0" placement="left" ngb></a>
    <!-- Content for Popover #1 -->
    <ng-template #popContent>
      <div class="d-none d-lg-block">
        <a [href]="(profileUrl | async)" target="_blank" class="form-control btn btn-sm btn-deconnect">Mon profil SingleKey ID</a><!-- href="https://stage.singlekey-id.com/myprofile/"  -->
      </div>

      <div class="d-none d-lg-block">
        <a (click)="logout()" class="form-control btn btn-sm btn-deconnect">Se déconnecter</a>
      </div>
      <div class="popover-responsive d-flex flex-column d-lg-none">
        <div class="optional">{{ userName$ | async }}</div>
        <a (click)="logout()" class="form-control btn btn-sm mt-1 btn-deconnect">Se déconnecter</a>
      </div>
      <!-- <a [routerLink]='["/authentication/logout"]' class="btn btn-sm btn-deconnect" [state]='{ local: true }'>Se déconnecter</a> -->
    </ng-template>
  </ng-container>
  <ng-template #signinBlock>
    <!-- <a class="btn btn-register d-none d-sm-none d-md-block" [routerLink]='["/authentication/register"]' tabindex="0"> -->
    <a class="btn btn-register d-none d-sm-none d-md-block" (click)="register()" tabindex="0">
      S'inscrire
    </a>
    <a class="btn btn-sign-in d-block" (click)="login()" tabindex="0">
      Se connecter
    </a>
  </ng-template>
</div>
