import { Injectable } from '@angular/core';
import {  Observable,  of,  defer, ReplaySubject } from 'rxjs';
import { UserService } from './user.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { tap, map,  first } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PermissionService {

 // private persmissionLoaded = false;
  private loaded = false;
  private _permissions = new ReplaySubject<string[]>(1);
  readonly permissions$ = this._permissions.asObservable();

  constructor(private userService: UserService, private ngxPermissionsService: NgxPermissionsService) { }



  loadPermissions() {
    return defer(() => this.loaded ?
      this.loadedPermissions() :
      this.getPermissions()
    );
  }

  clearPermission(): Observable<string[]> {
    this.ngxPermissionsService.flushPermissions();
    this.loaded = false;
    this._permissions.next([]);
    return of([]);
  }

  handlePermissions(load: boolean) {
    return defer(() => load ? this.loadPermissions() : this.clearPermission());
  }

  hasPermission(name: string) {
    return this._permissions.pipe(
      map(perm => !!perm && perm.includes(name))
    );
  }

  get hasPermissions$() {
    return this._permissions.pipe(
      map(perm => {
        return Object.keys(perm).length > 0;
      })
    );
  }

  private loadedPermissions() {
    return this.ngxPermissionsService.permissions$.pipe(
      map(perm => {
        Object.keys(perm)
      })
    );
  }

  private getPermissions() {
    return this.userService.getPermissions().pipe(
      first(),
      tap(p => {
        this.ngxPermissionsService.loadPermissions(p);
        this._permissions.next(p);
        this.loaded = true;
      }),
    );
  }
}
