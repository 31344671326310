import { Component, OnInit } from '@angular/core';
import { InterventionService } from '../services/intervention.service';

@Component({
  selector: 'app-my-interventions',
  templateUrl: './my-interventions.component.html',
  styleUrls: ['./my-interventions.component.scss']
})
export class MyInterventionsComponent implements OnInit {

  constructor(private interventionService: InterventionService) { }

  ngOnInit(): void {
    this.interventionService.getMyInterventions();
  }


}
