<div class="container">
  <div class="panel">
    <div class="section-title">Récapitulatif de l'intervention</div>
    <div *ngIf="info; else elseBlock">
      <div class="section-subtitle">{{info.message}}</div>
      <form (ngSubmit)="onSubmit()" *ngIf="info.idIntervention > 0" #infoForm="ngForm">
        <div class="form-group row">
          <label for="inputType" class="col-sm-3 col-form-label">Type d'intervention</label>
          <div class="col-sm-9">
            <input readonly type="text" class="form-control-plaintext" id="inputType" [ngModel]="info.typeIntervention"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="form-group row">
          <label for="inputCommanditaire" class="col-sm-3 col-form-label">Commanditaire</label>
          <div class="col-sm-9">
            <input readonly type="text" class="form-control-plaintext" id="inputCommanditaire"
              [ngModel]="info.nomCommanditaire" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="form-group row">
          <label for="inputDate" class="col-sm-3 col-form-label">Date de l'intervention</label>
          <div class="col-sm-9">
            <input readonly type="text" class="form-control-plaintext" id="inputDate" [ngModel]="info.date"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPeriod" class="col-sm-3 col-form-label">Période</label>
          <div class="col-sm-9">
            <input readonly type="text" class="form-control-plaintext" id="inputPeriod" [ngModel]="info.periode"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="form-group row">
          <label for="inputAppareil" class="col-sm-3 col-form-label">Appareil</label>
          <div class="col-sm-9">
            <input readonly type="text" class="form-control-plaintext" id="inputAppareil" [ngModel]="info.appareil"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="form-group row">
          <label for="inputModel" class="col-sm-3 col-form-label">Modèle</label>
          <div class="col-sm-9">
            <input readonly type="text" class="form-control-plaintext" id="inputModel" [ngModel]="info.modele"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="form-group row">
          <label for="inputClient" class="col-sm-3 col-form-label">Nom client</label>
          <div class="col-sm-9">
            <input readonly type="text" class="form-control-plaintext" id="inputClient" [ngModel]="info.nomClient"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="form-group row" *ngIf="info.typeClient == 'professionnel'">
          <label for="inputClientType" class="col-sm-3 col-form-label">Type client</label>
          <div class="col-sm-9">
            <input readonly type="text" class="form-control-plaintext" style="color: orange; font-weight: bold;"
              id="inputClientType" [ngModel]="info.typeClient" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="form-group row">
          <label for="inputCity" class="col-sm-3 col-form-label">City</label>
          <div class="col-sm-9">
            <input readonly type="text" class="form-control-plaintext" id="inputCity" [ngModel]="info.ville"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="form-group row">
          <label for="inputAddress" class="col-sm-3 col-form-label">Adresse</label>
          <div class="col-sm-9">
            <input readonly type="text" class="form-control-plaintext" id="inputAddress" [ngModel]="info.adresse"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="form-group row" *ngIf="info.listeMotifs?.length > 1">
          <label for="inputReason" class="col-sm-3 col-form-label col-form-label-lg">Motif</label>
          <div class="col-sm-9">
            <select class="custom-select custom-select-lg" id="inputReason" name="selectedReason"
              [(ngModel)]="selectedReason" required>
              <option *ngFor="let reason of info.listeMotifs" [value]="reason.idMotif">{{reason.libelle}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row" *ngIf="info.hasToInstallThermostat">
          <label for="hasInstalledThermostat" class="col-form-label col-form-label-lg">Avez-vous pu installer un
            thermostat au client ?</label>
          <div class="form-control">
            <label class="radio-inline">
              <input style="margin-left: 2rem;" name="hasInstalledThermostat" type="radio" value="OK"
                [(ngModel)]="hasInstalledThermostat" required> Oui
            </label>
            <label class="radio-inline">
              <input style="margin-left: 2rem;" name="hasInstalledThermostat" type="radio" value="KO"
                [(ngModel)]="hasInstalledThermostat" required> Non
            </label>
          </div>
        </div>
        <div *ngIf="hasInstalledThermostat == 'OK'" class="form-group row">
          <label for="typeThermostat" class="col-form-label col-form-label-lg">Quel est le type de thermostat installé
            ?</label>
          <div class="form-control">
            <label class="radio-inline">
              <input style="margin-left: 2rem;" name="typeThermostat" type="radio" value="01"
                [(ngModel)]="typeThermostat" required> Filaire
            </label>
            <label class="radio-inline">
              <input style="margin-left: 2rem;" name="typeThermostat" type="radio" value="02"
                [(ngModel)]="typeThermostat" required> Non filaire
            </label>
          </div>
        </div>
        <div *ngIf="hasInstalledThermostat == 'KO'" class="form-group row">
          <label for="inputReasonNonInstallation" class="col-sm-3 col-form-label">Sélectionnez une raison</label>
          <div class="col-sm-9">
            <select class="custom-select custom-select-lg" id="inputReasonNonInstallation"
              name="selectedReasonNonInstallation" [(ngModel)]="selectedReasonNonInstallation" required>
              <option *ngFor="let reason of listReasonNonInstallation" [value]="reason">{{reason}}</option>
            </select>
          </div>
        </div>
        <!-- Pro customer fields -->
        <div *ngIf="info.typeClient == 'professionnel'" class="form-group row">
          <label for="usageMixte" class="col-form-label col-form-label-lg">Une partie du bâtiment est-elle composée
            d'habitations ?</label>
          <div class="form-control">
            <label class="radio-inline">
              <input style="margin-left: 2rem;" name="usageMixte" type="radio" value="true" [(ngModel)]="usageMixte"
                required> Oui
            </label>
            <label class="radio-inline">
              <input style="margin-left: 2rem;" name="usageMixte" type="radio" value="false" [(ngModel)]="usageMixte"
                required> Non
            </label>
          </div>
        </div>
        <div *ngIf="info.typeClient == 'professionnel' && usageMixte == 'true'" class="form-group row">
          <label for="majoriteHabitation" class="col-form-label col-form-label-lg">Quelle est la proportion
            d'habitations dans le bâtiment ?</label>
          <div class="form-control">
            <label class="radio-inline">
              <input style="margin-left: 2rem;" name="majoriteHabitation" type="radio" value="true"
                [(ngModel)]="majoriteHabitation" required> plus de 50%
            </label>
            <label class="radio-inline">
              <input style="margin-left: 2rem;" name="majoriteHabitation" type="radio" value="false"
                [(ngModel)]="majoriteHabitation" required> moins de 50%
            </label>
          </div>
        </div>
        <div *ngIf="info.abreviationCommanditaire == 'hwa' && selectedReason != 4" class="form-group row">
          <label for="remplacementRequis" class="col-form-label col-form-label-lg">L'appareil doit-il être remplacé
            ?</label>
          <div class="form-control">
            <label class="radio-inline">
              <input style="margin-left: 2rem;" name="remplacementRequis" type="radio" [value]="true"
                [(ngModel)]="remplacementRequis" required> Oui
            </label>
            <label class="radio-inline">
              <input style="margin-left: 2rem;" name="remplacementRequis" type="radio" [value]="false"
                [(ngModel)]="remplacementRequis" required> Non
            </label>
          </div>
        </div>
        <div *ngIf="info.realised" class="form-group row">
          <label for="fileUpload" class="col-form-label col-form-label-lg">Joindre l'attestation d'entretien <small>(au
              format pdf)</small> :</label>
          <div class="form-control">
            <!-- TEMP : required or not ? -->
            <input (change)="onFileChange($event)" type="file" id="fileUpload" name="file" [(ngModel)]="file">
            <!-- <input type="file" id="fileUpload" name="file" [(ngModel)]="file" required> -->
          </div>
        </div>

        <button type="submit" class="btn btn-primary" [disabled]="!infoForm.form.valid">Confirmer</button>
      </form>
    </div>
    <ng-template #elseBlock>
      <div class="section-subtitle">{{status}}</div>
    </ng-template>
  </div>
</div>