import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { InterventionService } from '../services/intervention.service';
import { NgxPermissionsService, NgxPermissionsObject } from 'ngx-permissions';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-intervention',
  templateUrl: './intervention.component.html',
  styleUrls: ['./intervention.component.scss']
})
export class InterventionComponent implements OnInit {
  perms: Observable<NgxPermissionsObject>;

  constructor(private route: ActivatedRoute, private interventionService: InterventionService) { }

  ngOnInit() {
    this.route.queryParamMap.pipe(
      filter(p => p.has('clientId')),
      map(p => p.get('clientId'))
    )
    .subscribe(id => {
      this.interventionService.clientId = id;
    });
  }

}
