<div class="div-commanditaire">
    <h5 class="card-title color-title">{{commanditaire.typeIntervention}}</h5>
    <h6 class="card-subtitle mb-2 text-muted">Client de <b>{{commanditaire.nomCommanditaire}}</b></h6>
    <div class="pl-3">
        <p class="card-text font-italic">{{commanditaire.adresse}}</p>
        <p class="card-text font-italic">{{commanditaire.codePostal}} {{commanditaire.ville}}</p>
        <p *ngIf="commanditaire.codePorte" class="card-text font-italic">Code porte <b>{{commanditaire.codePorte}}</b></p>
        <p *ngIf="commanditaire.etage" class="card-text font-italic">étage <b>{{commanditaire.etage}}</b></p>
    </div>
    <br>
    <p class="card-text">{{commanditaire.nomClient}}</p>
    <p class="card-text pl-3 font-italic">{{commanditaire.contactClient}}</p>
    <p class="card-text pl-3 font-italic">{{commanditaire.email}}</p>
</div>