<tabset [justified]="true">
  <tab heading="Interventions reçues" *ngxPermissionsOnly="['RecevoirIntervention']">
    <div class="mt-4"><app-my-interventions></app-my-interventions>
    </div>
  </tab>
  <tab heading="Mes demandes de mises en relation" *ngxPermissionsOnly="['DemandeIntervention']">
    <button class="btn btn-primary my-4" [routerLink]="['/intervention/job']">Créer une demande d'intervention</button>
    <app-intervention-history></app-intervention-history>
  </tab>
</tabset>





