import { BehaviorSubject, Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, tap, startWith, take } from 'rxjs/operators';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public isAuthenticated: Observable<boolean>;
  public isShown: boolean = false;

   authenticating = false;
   selectedPatm : string;



  constructor(private router: Router, private activatedRoute: ActivatedRoute,public oidcSecurityService: OidcSecurityService) {}

  ngOnInit() {


    this.isAuthenticated = this.oidcSecurityService.isAuthenticated$.pipe(map((res => res.isAuthenticated)));
    var res = this.isAuthenticated.subscribe(data =>
    {
      if (data == true) {
        this.router.navigate(['/availability'])

      }

    })


  }



}
