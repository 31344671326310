import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  innerHtml: string;
  privacyPolicy$: Observable<any>;

  url: string = 'https://policies.ttprivacy.com/api/v1/policies/application/5c8aa7e3-deb1-4651-bf91-f5bbafeb49f9/country/FR/language/fr-FR';

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.privacyPolicy$ = this.http.get(this.url);
  }
}
