import { Injectable } from '@angular/core';
import { WorkflowService } from '../services/workflow.service';
import { QuestionOption } from '../models/question';
import { JobType } from '../models/job-type.model';
import { ContactInfo } from '../models/contact-info';
import { STEPS } from '../models/workflow.model';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  private jobType: JobType;
  private options: QuestionOption[];
  private personal: ContactInfo;
  private partners: number[];

  private isPersonalFormValid = false;
  private isJobFormValid = false;
  private isQuestionFormValid = false;
  private isPartnerFormValid = false;

  constructor(private workflowService: WorkflowService) { }

  setSelectedJobType(jobType: JobType, jobSubType: JobType) {
    this.isJobFormValid = true;
    this.jobType = {...jobType}; // clone
    this.jobType.subTypes = [{...jobSubType}];
    this.workflowService.validateStep(STEPS.job);
  }

  getSelectedJobType(): JobType {
    return this.jobType;
  }

  setSelectedOptions(options: QuestionOption[]) {
    this.isQuestionFormValid = true;
    this.options = [...options];
    this.workflowService.validateStep(STEPS.question);
  }

  getSelectedOptions(): QuestionOption[] {
    return this.options;
  }

  setPersonalInfo(personal: ContactInfo) {
    this.isPersonalFormValid = true;
    this.personal = {...personal};
    this.workflowService.validateStep(STEPS.info);
  }

  getPersonalInfo(): ContactInfo {
    return this.personal;
  }

  setPartners(partners: number[]) {
    this.isPartnerFormValid = true;
    this.partners = [...partners];
    this.workflowService.validateStep(STEPS.partner);
  }

  getPartners(): number[] {
    return this.partners;
  }

  resetSelectedOption() {
    this.isQuestionFormValid = false;
    this.workflowService.resetStep(STEPS.question);
  }

  resetSelectedJobType() {
    this.isJobFormValid = false;
    this.workflowService.resetStep(STEPS.job);
  }

  resetPersonalInfo() {
    this.isPersonalFormValid = false;
    this.workflowService.resetStep(STEPS.info);
  }

  resetPartners() {
    this.isPartnerFormValid = false;
    this.workflowService.resetStep(STEPS.partner);
  }

  resetFormData() {
    this.workflowService.resetSteps();
    this.jobType = null;
    this.options = null;
    this.personal = null;
    this.partners = null;
    this.isPersonalFormValid = this.isQuestionFormValid = this.isJobFormValid = this.isPartnerFormValid = false;
  }

  isFormValid(): boolean {
    return this.isPersonalFormValid &&
            this.isQuestionFormValid &&
            this.isJobFormValid &&
            this.isPartnerFormValid;
}
}
