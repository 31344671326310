
import { Component, OnInit } from '@angular/core';
//import { AuthorizeService, AuthenticationResultStatus } from '@core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoginActions, QueryParameterNames, ApplicationPaths, ReturnUrlType } from '../../api-authorization.constants';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { StorageService } from '@core/services/storage.service';

const RETURNURL_KEY = 'returnurl';
// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

 public message = new BehaviorSubject<string>(null);

   authenticating = false;



  storage: Storage;

  constructor(private oidcSecurityService: OidcSecurityService, private activatedRoute: ActivatedRoute, private storageService: StorageService) {
    this.storage = this.storageService.get();
  }

  ngOnInit(): void {
   this.storage.setItem(RETURNURL_KEY, this.getReturnUrl());
   this.oidcSecurityService.authorize(null, { customParams: { style_id: 'tt_elm' } });
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (this.activatedRoute.snapshot.queryParams as INavigationState).returnUrl;
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    //if (fromQuery &&
    //  !(fromQuery.startsWith(`${window.location.origin}/`) ||
    //    /\/[^\/].*/.test(fromQuery))) {
    //  // This is an extra check to prevent open redirects.
    //  throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    //}
    return (state && state.returnUrl) ||
      fromQuery ||
      ApplicationPaths.DefaultLoginRedirectPath;
    // return ApplicationPaths.DefaultLoginRedirectPath;
  }

}

interface INavigationState {
  [ReturnUrlType]: string;
}
