<div class="div-history-info">
    <div *ngIf="intervention.commanditaireIntervention == null then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <div class="section-subtitle">Contact</div>
        <address>
          {{intervention.contact.firstName}} {{intervention.contact.lastName}}<br>
          {{intervention.contact.address}}<br>
          {{intervention.contact.address2}}<br>
          {{intervention.contact.zip}} {{intervention.contact.city}}<br>
          <abbr>Tel : </abbr>{{intervention.contact.phone}}<br>
          <a [href]="'mailto:' + intervention.contact.email">{{intervention.contact.email}}</a>
        </address>
        <p>{{intervention.contact.comments}}</p>
        <div class="section-subtitle">Informations supplémentaires</div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let question of intervention.questions">
            <em>{{question.question}}</em><br>
            {{question.answer}}
          </li>
        </ul>
    </ng-template>
    <ng-template #elseBlock>
        <div class="section-subtitle">Contact</div>
        <address>
          {{intervention.commanditaireIntervention.nomClient}}<br>
          {{intervention.commanditaireIntervention.addresse}}<br>
          {{intervention.commanditaireIntervention.codePostal}} {{intervention.commanditaireIntervention.ville}}<br>
          Code porte {{intervention.commanditaireIntervention.codePorte}} étage {{intervention.commanditaireIntervention.etage}}<br>
          <abbr>Tel : </abbr>{{intervention.commanditaireIntervention.contactClient}}<br>
          <a [href]="'mailto:' + intervention.contact.email">{{intervention.commanditaireIntervention.email}}</a>
        </address>
        <p>{{intervention.commanditaireIntervention.commentaires}}</p>
        <div class="section-subtitle">Informations supplémentaires</div>        
        <p class="card-text">Appareil</p>
        <p class="card-text font-italic">Marque {{intervention.commanditaireIntervention.appareil}}</p>
        <p class="card-text font-italic">Modèle {{intervention.commanditaireIntervention.modele}}</p>
    </ng-template>
    <div *ngIf="intervention.reason != null" class="section-subtitle">Motif du refus</div>
    <p *ngIf="intervention.reason != null">{{intervention.reason}}</p>
    <div class="modal-footer">
        <button type="button" class="btn btn-info" (click)="bsModalRef.hide()">Fermer</button>
      </div>
</div>
