import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EventTypes, OidcClientNotification, PublicEventsService, OpenIdConfiguration } from 'angular-auth-oidc-client';


@Injectable({
  providedIn: 'root'
})
export class SecretService {

  tokenEndpoint: string;
  clientSecret: string;

  constructor(private eventService: PublicEventsService) {
    this.eventService
            .registerForEvents()
            .pipe(
              filter((notification) => notification.type === EventTypes.ConfigLoaded),
              map((notification: OidcClientNotification<OpenIdConfiguration>) => notification.value))
            .subscribe((config) => {
              this.tokenEndpoint = config.authWellknownEndpointUrl;
              this.clientSecret = config.customParamsAuthRequest?.client_secret as string;
            });
  }
}
