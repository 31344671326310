import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AvailabilityModule } from './../availability/availability.module';
import { SpinnerComponent } from './spinner/spinner.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import * as fromComponents from './components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [SpinnerComponent],
  imports: [
    // NgxPermissionsModule.forRoot(),
    // CommonModule,
    // ModalModule.forRoot(),
    // PopoverModule.forRoot(),
    // TabsModule.forRoot(),
    // BsDatepickerModule.forRoot(),
    // TimepickerModule.forRoot(),
    // FormsModule,
    // ReactiveFormsModule,
    // MatProgressSpinnerModule,
    NgxPermissionsModule,
    CommonModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgbModule,


  ],

  exports: [
    CommonModule,
    TabsModule,
    ModalModule,
    FormsModule,
    PopoverModule,
    BsDatepickerModule,
    TimepickerModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    MatProgressSpinnerModule,
    CollapseModule,
    SpinnerComponent,
   // ApiAuthorizationModule,
    ]
})
export class SharedModule { }
