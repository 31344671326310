<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate>
    <div class="modal-header">
        <p class="modal-title pull-left">Fiche d'intervention n° <span class="color-title">{{intervention.id}}</span></p>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="planifiedDate">Date</label>
            <input type="date" class="form-control" id="planifiedDate" formControlName="date">
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary float-left" [disabled]="!formGroup.valid">Valider</button>
        <button type="button" class="btn btn-info float-right" (click)="bsModalRef.hide()">Fermer</button>
    </div>
</form>