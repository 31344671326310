<div class="div-intervention-info">
    <div *ngIf="intervention.commanditaireIntervention == null then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <h5 class="card-title">{{intervention.date ? (intervention.date | date) : 'à planifier'}}</h5>
        <h6 class="card-subtitle mb-2 text-muted">Demandeur</h6>
        <p class="card-text font-italic">Proposé par <b>{{intervention.partners}}</b></p>
        <p class="card-text"></p>
        <p class="card-text"></p>
        <p class="card-text"></p>
        <br>
        <div class="section-subtitle">Informations supplémentaires</div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let question of intervention.questions">
            <em>{{question.question}}</em><br>
            {{question.answer}}
          </li>
        </ul>
    </ng-template>
    <ng-template #elseBlock>
        <h5 class="card-title">{{intervention.commanditaireIntervention.date}}</h5>
        <h6 class="card-subtitle mb-2 text-muted">Demandeur</h6>
        <img src="{{intervention.commanditaireIntervention.logo}}">
        <p class="card-text"></p>
        <p class="card-text"></p>
        <p class="card-text"></p>
        <br>
        <p class="card-text">Appareil</p>
        <p class="card-text font-italic">Marque {{intervention.commanditaireIntervention.appareil}}</p>
        <p class="card-text font-italic">Modèle {{intervention.commanditaireIntervention.modele}}</p>
    </ng-template>
</div>
