<div class="row">
  <div class="col-xl-8">
    <div class="row text-center">
      <div class="col-md-4">
        <div class="btn-group">
          <div
            class="btn btn-primary"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
          >
            Précédent
          </div>
          <div
            class="btn btn-outline-secondary"
            mwlCalendarToday
            [(viewDate)]="viewDate"
          >
            Aujourd'hui
          </div>
          <div
            class="btn btn-primary"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
          >
            Suivant
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h3>
          {{
            viewDate
              | calendarDate: view + "ViewTitle":locale:weekStartsOn:excludeDays
          }}
        </h3>
      </div>
      <div class="col-md-4">
        <div class="btn-group">
          <div
            class="btn btn-primary"
            (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month"
          >
            Mois
          </div>
          <div
            class="btn btn-primary"
            (click)="setView(CalendarView.Week)"
            [class.active]="view === CalendarView.Week"
          >
            Semaine
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="events$ | async; else loading; let interventions">
      <div class="my-3" [ngSwitch]="view">
        <mwl-calendar-month-view
          *ngSwitchCase="CalendarView.Month"
          [viewDate]="viewDate"
          [excludeDays]="excludeDays"
          [locale]="locale"
          [weekStartsOn]="weekStartsOn"
          [events]="interventions"
          (dayClicked)="changeDay($event.day.date)"
          (eventClicked)="eventClicked($event)"
          (beforeViewRender)="beforeMonthViewRender($event)"
          [refresh]="refresh"
          [cellTemplate]="customCellTemplate"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="CalendarView.Week"
          dayStartHour="11"
          dayEndHour="12"
          [hourSegments]=hourSegments
          hourSegmentHeight="50"
          [viewDate]="viewDate"
          [excludeDays]="excludeDays"
          [locale]="locale"
          [weekStartsOn]="weekStartsOn"
          [events]="interventions"
          (eventClicked)="eventClicked($event)"
          (beforeViewRender)="beforeWeekViewRender($event)"
          [refresh]="refresh"
        >
        </mwl-calendar-week-view>
      </div>
    </div>
  </div>
  <div class="col-xl-4">
    <div *ngIf="selectedIntervention$ | async as intervention">
      <app-intervention-card [intervention]="intervention" (interventionUpdated)="refreshInterventions()"></app-intervention-card>
    </div>

  </div>
</div>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <!-- <span class="cal-day-badge" *ngIf="day.badgeTotal > 0"
      >{{ day.badgeTotal }}</span
    > -->
    <span class="cal-day-number"
      >{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span
    >
  </div>
  <div style="margin: 5px;" *ngIf="day.badgeTotal > 0"
    ><strong>{{ day.events.length }}</strong>&nbsp;<small>intervention(s)</small></div
  >
</ng-template>

<ng-template #loading>
  <div class="text-center mt-5">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>

