<div class="div-contact">
    <h5 class="card-title color-title">{{contact.motif}}</h5>
    <h6 class="card-subtitle mb-2 text-muted">Informations client</h6>
    <div class="pl-3">
        <p class="card-text font-italic">{{contact.address}}</p>
        <p *ngIf="contact.address2" class="card-text font-italic">{{contact.address2}}</p>
        <p class="card-text font-italic">{{contact.zip}} {{contact.city}}</p>
    </div>
    <br>
    <p class="card-text">{{contact.firstName}} {{contact.lastName}}</p>
    <p class="card-text pl-3 font-italic">{{contact.phone}}</p>
    <p class="card-text pl-3 font-italic">{{contact.email}}</p>
</div>