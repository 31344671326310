
import { OidcSecurityService } from 'angular-auth-oidc-client';
//import { ApplicationPaths } from './../../api-authorization/api-authorization.constants';
import { Injectable } from '@angular/core';
//import { UserManagerSettings } from 'oidc-client';

import { OpenIdConfiguration } from 'angular-auth-oidc-client';
import { ApplicationName, ApplicationPaths } from '@core/api-authorization.constants';



@Injectable()
export class ConfigService {


  async load(): Promise<OpenIdConfiguration> {

    try {
      const res = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl);
      const customConfig = await res.json();
      return await new Promise((resolve) => {
        const config = {
          silentRenew: true,

          //responseType: "code",
          // commentez en fonction de ce qu'on veut
          useRefreshToken: true,
          //silentRenewUrl: customConfig.redirectUrl.replace(/\/$/, '') + '/silent-renew.html',
          //tokenRefreshInSeconds: 10,
          configId: 'openIdConfig1',
          //useRefreshToken: true,
          //silentRenewUrl: customConfig.redirectUrl.replace("/%2F/gauthentication", "").replace("/%2F/glogin-callback", "").replace(/\/$/, "") + '/silent-renew.html',
          //silentRenewUrl: "http://localhost:44313/silent-renew.html",
          //eagerLoadAuthWellKnownEndpoints: false,
          historyCleanupOff: false,
          tokenRefreshInSeconds: 10,
          maxIdTokenIatOffsetAllowedInSeconds: 7200,
          //renewTimeBeforeTokenExpiresInSeconds: 30,
          //storage: sessionStorage,
          //maxIdTokenIatOffsetAllowedInSeconds: 43200,
          //disableIatOffsetValidation: true,
          // disableRefreshIdTokenAuthTimeValidation: true,
          //enableIdTokenExpiredValidationInRenew: false,
          ...customConfig
        } as OpenIdConfiguration;
        if (customConfig.client_secret) {
          config.customParamsAuthRequest = {
            client_secret: customConfig.client_secret,
          };
        }
        resolve(config);
      });
    } catch (error) {
      console.error(`Could not load settings for '${ApplicationName}'`);
    }
  }

}

