export class ContactInfo {
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  zip: string;
  city: string;
  email: string;
  phone: string;
  comments: string;
}
