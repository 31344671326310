<div class="panel h-100">
  <div class="row ml-0">
    <div class="section-title">J'organise mon activité</div>
  </div>
  <div class="row">
    <div class="col-xl-8">
      <app-calendar-settings></app-calendar-settings>
      <app-skill-list></app-skill-list>
    </div>
    <div class="col-xl-4">
      <app-postcode-list></app-postcode-list>
    </div>
  </div>
</div>
