import { Component, OnInit, Input } from '@angular/core';
import { Commanditaire } from '../models/commanditaire';
import { Subject, InteropObservable } from 'rxjs';

@Component({
  selector: 'app-intervention-commanditaire',
  templateUrl: './intervention-commanditaire.component.html',
  styleUrls: ['./intervention-commanditaire.component.scss']
})
export class InterventionCommanditaireComponent implements OnInit {

  @Input() commanditaire: Commanditaire;

  public onProcessed: Subject<Commanditaire>;

  constructor() { }

  ngOnInit() {
    this.onProcessed = new Subject();
  }

  accept() {
    this.onProcessed.next(this.commanditaire);
  }
}
