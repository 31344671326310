import { Injectable, Inject } from '@angular/core';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Question, QuestionOption } from '../models/question';
import { last } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  private _question = new BehaviorSubject<Question>(null);
  private questionStack: Question[];
  private answers: QuestionOption[];

  //answer: QuestionOption = null;

  readonly question = this._question.asObservable();

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.questionStack = [];
    this.answers = [];
   }

  getFirstQuestion(type: number) {
    this.questionStack = [];
    this.answers = [];

    this.http.get<Question>(this.baseUrl + 'api/intervention/questions/first/' + type).subscribe(
      res => {
        this.questionStack.push(res);
        this._question.next(res);
      }
    );
  }

  getNextQuestion(option: QuestionOption) {
    this.answers.push(option);

    if (option.nextQuestionId && option.nextQuestionId != null) {
      this.http.get<Question>(this.baseUrl + 'api/intervention/questions/' + option.nextQuestionId).subscribe(
        res => {
          this.questionStack.push(res);
          this._question.next(res);
        }
      );
    } else {
      this._question.next(null);
    }
  }

  hasPreviousQuestion(): boolean {
    return this.questionStack.length > 1;
  }

  getPreviousQuestion() {
    if (this.hasPreviousQuestion()) {
      this.questionStack.pop();
    }
    this._question.next(this.questionStack.pop());
    this.answers.pop();
  }

  getLastQuestion() {
    this._question.next(this.questionStack.pop());
    this.answers.pop();
  }

  addAnswer(answer: QuestionOption) {
    this.answers.push(answer);
  }

  getAnswers(): QuestionOption[] {
    return this.answers;
  }
}
