import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from '@core/services';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  cubeUrl: Observable<string>;

  constructor(private urlService: UrlService, private router : Router) {}

  ngOnInit(): void {
    this.cubeUrl = this.urlService.getCubeUrl().pipe(
      startWith('#'),
    );
  }
}
