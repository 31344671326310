import { AdminService } from './../services/admin.service';
import { AuthorizeService } from '@core/services';
import { UserService } from './../../core/services/user.service';
import { map, shareReplay, mergeMap, tap, switchMap, startWith } from 'rxjs/operators';
import { forkJoin, Observable, zip } from 'rxjs';
import { AvailabilityService } from './../services/availability.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PatmItem } from '../models/patm-item';
//import { stringify } from 'querystring';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  organizationId$: Observable<number>;
  patms$: Observable<PatmItem[]>;
  isAdminView: boolean;

  selectedPatm: number;

  constructor(public router: Router, private activatedRoute: ActivatedRoute, private availabilityService: AvailabilityService, private userService: UserService, private adminService: AdminService) { }

  ngOnInit(): void {
    // used for admin button
    this.organizationId$ = this.userService.getOrganizationId();

    this.isAdminView = this.router.url.includes('/admin');

    if (this.isAdminView) {
      console.log("isAdminView NAVMENU")
      let idValue = this.router.url.split('/');
      console.log(idValue);
      let id = idValue[idValue.length - 1];

      this.patms$ = this.activatedRoute.params.pipe(
        switchMap(params => {
          console.log("params", params)
          this.selectedPatm = Number(id);

          return this.availabilityService.getPatms();
        })
      )
    }
  }


  patmChanged() {
    this.userService.setOrganizationId(this.selectedPatm).subscribe(res => {

      // modify the url by replacing old patm with the new
      let splitedUrl = this.router.url.split('/')
      let indexOfId = splitedUrl.indexOf('admin') + 1
      splitedUrl[indexOfId] = this.selectedPatm.toString();

      let url = splitedUrl.join('/')

      //this.adminService.patmChanged.emit(true);

      // // refresh current location
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([url]);
      });
    });
  }
}
