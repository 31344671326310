<div class="panel">
  <div class="section-title">Créer une demande d'intervention</div>
  <div class="section-subtitle">{{type?.subTypes[0]?.name}}</div>

  <div class="card" *ngIf="question$|async as question">
    <!-- <p class="card-header" style="text-align:center">{{question.name}}</p> -->
    <div class="card-body">
      <p class="card-title">{{question.name}}</p>
        <button type="button" class="btn btn-primary btn-lg btn-block" (click)="next(option)" *ngFor="let option of question.options">{{option.name}}</button>
    </div>
  </div>
</div>
<div class="mt-1">
  <a type="button" class="btn btn-light" (click)="previous()">Etape précedente</a>
</div>
