


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AvailabilityRoutingModule } from './availability-routing.module';
import { SettingsComponent } from './settings/settings.component';
import { CalendarComponent } from './calendar/calendar.component';
import { SharedModule } from '@shared/shared.module';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { defineLocale } from 'ngx-bootstrap/chronos';
//import { frLocale } from 'ngx-bootstrap/locale';
import { frLocale } from 'ngx-bootstrap/chronos';
import { CalendarSettingsComponent } from './calendar-settings/calendar-settings.component';
import { AvailabilitySettingsComponent } from './availability-settings/availability-settings.component';
import { SkillListComponent } from './skill-list/skill-list.component';
import { PostcodeListComponent } from './postcode-list/postcode-list.component';
import { AvailabilityComponent } from './availability/availability.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HistoryComponent } from './history/history.component';
import { InterventionsComponent } from './interventions/interventions.component';
import { PlanningComponent } from './planning/planning.component';
import { InterventionInfoComponent } from './intervention-info/intervention-info.component';
import { InterventionContactComponent } from './intervention-contact/intervention-contact.component';
import { InterventionCommanditaireComponent } from './intervention-commanditaire/intervention-commanditaire.component';
import { DateAdapter, CalendarModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { InterventionModalComponent } from './intervention-modal/intervention-modal.component';
import { InterventionCardComponent } from './intervention-card/intervention-card.component';
import { InterventionCauseModalComponent } from './intervention-cause-modal/intervention-cause-modal.component';
import { HistoryInfoModalComponent } from './history-info-modal/history-info-modal.component';
import { UpdatePostcodesComponent } from './update-postcodes/update-postcodes.component';
import { UpdateSkillsComponent } from './update-skills/update-skills.component';
import { ModalModule } from 'ngx-bootstrap/modal';


defineLocale('fr', frLocale);
registerLocaleData(localeFr);

@NgModule({
   declarations: [SettingsComponent, CalendarComponent, CalendarSettingsComponent, AvailabilitySettingsComponent, SkillListComponent,
     PostcodeListComponent, AvailabilityComponent, NavMenuComponent, HistoryComponent, InterventionsComponent, PlanningComponent,
     InterventionInfoComponent, InterventionContactComponent, InterventionCommanditaireComponent, InterventionModalComponent,
      InterventionCardComponent, InterventionCauseModalComponent, HistoryInfoModalComponent,
       UpdatePostcodesComponent, UpdateSkillsComponent ],

  imports: [
    SharedModule,

  //  ModalModule.forRoot(),
   AvailabilityRoutingModule,
   CalendarModule.forRoot({ provide: DateAdapter ,useFactory: adapterFactory }),
  ]
})
export class AvailabilityModule { }

