import { PermissionService } from './permission.service';
import { AuthenticatedResult, LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { Injectable } from '@angular/core';
// import { User, UserManager, WebStorageStateStore, Log } from 'oidc-client';
import { BehaviorSubject, concat, from, Observable, ReplaySubject } from 'rxjs';
import { tap, first, switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthorizeService {


  private checkAuthCompleted$ = new ReplaySubject(1)

  constructor(private oidcSecurityService: OidcSecurityService, private permissionService:PermissionService){
    this.checkAuthCompleted$.subscribe(val => console.log("checkAuthCompleted$ : ", val))
  }

  public get isAuthenticated$():Observable<AuthenticatedResult>{
   return this.checkAuthCompleted$.pipe(
     first(),
     switchMap((_) =>this.oidcSecurityService.isAuthenticated$)
   )
  }

  public checkAuth(): Observable<LoginResponse> {
    return this.oidcSecurityService.checkAuth().pipe(
      tap((response) => {
        if (response.isAuthenticated) {
          this.permissionService.handlePermissions(true).subscribe();
        } else {
          let refreshToken = this.oidcSecurityService.getRefreshToken();

          if (!response.isAuthenticated && !!refreshToken) {
            console.log("Disconnected");
            this.oidcSecurityService.forceRefreshSession().subscribe((result) => {
              this.permissionService.handlePermissions(true).subscribe();
            });
          }
        }
      }),
      tap((_) => this.checkAuthCompleted$.next()));
  }
}
