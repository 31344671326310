import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { PartnerService } from '../services/partner.service';
import { InterventionAnswer } from '../models/intervention-answer';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  message = 'En cours ...';

  isAccepted = false;

  idIntervention = 0;
  typeIntervention = '';
  nomCommanditaire = '';
  abreviationCommanditaire = '';
  date = '';
  periode = '';
  appareil = '';
  modele = '';
  nomClient = '';
  ville = '';
  adresse = '';
  complement = '';
  etage = '';
  appartement = '';
  codePorte = '';
  contactClient = '';
  commentaires = '';
  hasToInstallThermostat :boolean;

  constructor(private route: ActivatedRoute, private partnerService: PartnerService) { }

  ngOnInit() {
    this.route.queryParamMap.pipe(
      map(p => ({
        guid: p.get('guid'),
        idOrganisation: +p.get('idOrganisation'),
        accepted: this.toBoolean(p.get('accepted'))} as InterventionAnswer)),
      switchMap(info => this.partnerService.updateIntervention(info))
    )
    .subscribe(info => {
      //this.message = info.isAccepted ? 'Intervention acceptée.' : 'Intervention refusée.';
      this.message = info.messagePage;
      this.isAccepted = info.isAccepted;

      if (info.isAccepted) {

        this.idIntervention = info.idIntervention;
        this.typeIntervention = info.typeIntervention;
        this.nomCommanditaire = info.nomCommanditaire;
        this.abreviationCommanditaire = info.abreviationCommanditaire;
        this.date = info.date;
        this.periode = info.periode;
        this.appareil = info.appareil;
        this.modele = info.modele;
        this.nomClient = info.nomClient;
        this.ville = info.ville;
        this.adresse = info.adresse;
        this.complement = info.complement;
        this.etage = info.etage;
        this.appartement = info.appartement;
        this.codePorte = info.codePorte;
        this.contactClient = info.contactClient;
        this.commentaires = info.commentaires;
        this.hasToInstallThermostat = info.hasToInstallThermostat;

      }

    });
  }

  toBoolean = (value: string | number | boolean): boolean =>
    [true, 'true', 'True', 'TRUE', '1', 1].includes(value)
}
