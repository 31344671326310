import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AdminService {

  public patmChanged : EventEmitter<boolean> = new EventEmitter();

  constructor() { }
}
