
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgxPermissionsService } from 'ngx-permissions';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AuthorizeService } from './authorize.service';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { RegistrationStatus } from 'app/availability/models/registration-status';



@Injectable({
  providedIn: 'root'
})
export class UserService {


  private cachedStatus$: Observable<RegistrationStatus>;
  private reload$ = new Subject();



  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private ngxPermissionsService: NgxPermissionsService, oidcClient:OidcSecurityService) {


   }


  getPermissions(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + 'api/user/permissions');
  }

  getOrganizationId(): Observable<number> {

    return this.http.get<number>(this.baseUrl + 'api/user/organization');
  }


  // setOrganizationId(organizationId: number): Observable<any> {
  //   return this.http.post(this.baseUrl + 'api/user/organization?organizationId='+organizationId, null);
  // }
  setOrganizationId(organizationId: number): Observable<any> {
   // console.log("Orga id passe", organizationId);
  //  console.log("lien appellé",this.baseUrl + 'api/user/organization?organizationId='+organizationId);
    return this.http.post(this.baseUrl + 'api/user/organization?organizationId='+organizationId, null);
  }

  deleteOrganizationId(): Observable<any> {
    return this.http.delete(this.baseUrl + 'api/user/organization');
  }
}
