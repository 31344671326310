import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Intervention } from '../models/intervention';
import { AvailabilityService } from '../services/availability.service';
import { InterventionUpdate } from '../models/intervention-update';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@core/services';
import { LoaderService } from '@shared/services/loader.service';

@Component({
  selector: 'app-intervention-cause-modal',
  templateUrl: './intervention-cause-modal.component.html',
  styleUrls: ['./intervention-cause-modal.component.scss']
})
export class InterventionCauseModalComponent implements OnInit {
  intervention: Intervention;
  formGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, public bsModalRef: BsModalRef, private availabilityService: AvailabilityService, private notifyService: NotificationService, private loaderService: LoaderService) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      reason: [''],
    });
  }

  get reason() {
    return this.formGroup.get('reason');
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    const interventionForm = this.formGroup.value as Intervention;
    if(this.intervention.commanditaireIntervention == null)
    {
      interventionForm.id = this.intervention.id;
      this.leaveIntervention(interventionForm)
    }
    else
    {
      interventionForm.id = this.intervention.commanditaireIntervention.idIntervention;
      this.leaveInterventionCommanditaire(interventionForm)
    }
  }

  leaveIntervention(interventionUpdate) {
    const data = {
      idIntervention: interventionUpdate.id,
      accepted: false,
      reason: interventionUpdate.reason
    } as InterventionUpdate;

    this.loaderService.show();

    this.availabilityService.acceptOrRefusalIntervention(data).subscribe(() => {
      this.loaderService.hide();
      this.notifyService.showSuccess('Intervention rejetée', 'Message');
      this.bsModalRef.hide();
    });
  }

  leaveInterventionCommanditaire(interventionUpdate) {
    const data = {
      idIntervention: interventionUpdate.id,
      accepted: false,
      reason: interventionUpdate.reason
    } as InterventionUpdate;

    this.loaderService.show();

    this.availabilityService.updateInterventionAfterAcceptanceOrRefusal(data).subscribe(() => {
      this.loaderService.hide();
      this.notifyService.showSuccess('Intervention refusée', 'Message');
      this.bsModalRef.hide();
    });
  }
}
