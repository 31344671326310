<span class="link" (click)="openModal(updateSkillsTemplate)">(modifier)</span>

<ng-template #updateSkillsTemplate>
    <h1>Vos compétences</h1>

        <ng-container *ngIf="skills$ | async as skills; else loading">
            <div class="overflow-auto" style="max-height: 70vh;">
                <div *ngFor="let skill of skills; let i = index">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [id]="skill.id"
                            [checked]="isCheckedSkill(skill)" (change)="checkOrUncheckSkill(skill, $event)" [disabled]="checkIfDeviceType(isAdmin$ | async, skill)">
                        <label class="form-check-label" [for]="skill.id">{{skill.name}}</label>
                    </div>
                </div>
            </div>
            <div class="mt-2 d-flex flex-row-reverse">
                <button *ngIf="!isSaving" class="btn btn-primary" (click)="onSubmit()">Enregistrer</button>
                <button *ngIf="isSaving" class="btn btn-primary" disabled><span class="spinner-border spinner-border-sm"
                        role="status" aria-hidden="true"></span> Merci de patienter...</button>
            </div>

        </ng-container>
</ng-template>

<ng-template #loading>
    <div class="col-12 my-5">
        <mat-spinner style="left: unset; transform: unset;" class="spinner mx-auto"></mat-spinner>
    </div>
</ng-template>