import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { JobType } from '../models/job-type.model';
import { QuestionOption } from '../models/question';
import { ContactInfo } from '../models/contact-info';
import { Organisation } from '../models/organisation';
import { shareReplay, catchError, tap, map } from 'rxjs/operators';
import { InterventionRequest } from '../models/intervention-request';
import { Intervention } from '../models/intervention';
import { NotificationService } from '@core/services';

@Injectable({
  providedIn: 'root'
})
export class InterventionService {

  private _myInterventions = new BehaviorSubject<Intervention[]>([]);
  private cachedJobTypes$: Observable<JobType[]>;

  readonly myInterventions$ = this._myInterventions.asObservable();

  clientId?: string;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
    private notifyService: NotificationService) { }

  get jobTypes(): Observable<JobType[]> {
    if (!this.cachedJobTypes$) {
      this.cachedJobTypes$ = this.getAllJobTypes().pipe(shareReplay(1));
    }

    return this.cachedJobTypes$;
  }

  private getAllJobTypes(): Observable<JobType[]>  {
    return this.http.get<JobType[]>(this.baseUrl + 'api/intervention/jobs')
    .pipe(
      tap(_ => this.log('fetched job types'))
    );
  }

  getMatchingOrganisations(questionOptions: QuestionOption[], info: ContactInfo): Observable<Organisation[]>  {
    const ids = questionOptions.map(q => q.id).join(',');

    return this.http.get<Organisation[]>(this.baseUrl + 'api/intervention/search?optionIds=' + ids + '&postCode=' + info.zip)
    .pipe(
      tap(_ => this.log('fetched organisations')),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          return of([]);
        } else {
          return throwError(error);
        }
      })
    );
  }

  createIntervention(request: InterventionRequest): Observable<boolean> {
    return this.http.post(this.baseUrl + 'api/intervention/request', request, { observe: 'response' })
    .pipe(
      map((res) => {
        return res.status === 201;
      })
    );
  }

  getMyInterventions() {
    return this.http.get<Intervention[]>(this.baseUrl + 'api/intervention/mine')
    .pipe(
      tap(_ => this.log('fetched interventions')),
    ).subscribe(res => {
      this._myInterventions.next(res);
    });
  }

  getInterventionHistory(): Observable<Intervention[]> {
    return this.http.get<Intervention[]>(this.baseUrl + 'api/intervention/history')
    .pipe(
      tap(_ => this.log('fetched intervention history')),
    );
  }

  assignIntervention(interventionId: number) {
    return this.http.post<number>(this.baseUrl + 'api/intervention/' + interventionId + '/assign', {}).pipe(
      // refresh interventions
      tap(_ => this.getMyInterventions())
    );
  }

  getContactInfo(): Observable<ContactInfo> {
    if (!this.clientId) {
      return of(new ContactInfo());
    }

    return this.http.get<ContactInfo>(this.baseUrl + 'api/intervention/contact?clientId=' + this.clientId);
  }


  private log(message: string) {
    //console.log('Message: ' + message);
    //this.notifyService.showSuccess(message, 'Message');
  }
}
