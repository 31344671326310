import { UrlService } from './url.service';
import { NotificationService } from './notification.service';
import { AuthorizeService } from './authorize.service';
import { PermissionService } from './permission.service';
import { UserService } from './user.service';

export const services: any[] = [UrlService, NotificationService,
  AuthorizeService, PermissionService, UserService];

export * from './url.service';
export * from './notification.service';
export * from './authorize.service';
export * from './permission.service';
export * from './user.service';
