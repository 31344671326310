import { SpinnerComponent } from './../shared/spinner/spinner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AvailabilityComponent } from './../availability/availability/availability.component';


import { NgxPermissionsModule } from 'ngx-permissions';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NgModule, LOCALE_ID, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './http-error.interceptor';

import * as fromComponents from './components';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { ConfigService } from './services/config.service';
import { FooterComponent } from './components/footer/footer.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { LegalNoticesComponent } from './components/legal-notices/legal-notices.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { SalesTermsComponent } from './components/sales-terms/sales-terms.component';
import { LoginMenuComponent } from './components';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AuthModule, LoginResponse, OidcConfigService, OidcSecurityService, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded_one.guard';
import { SecretInterceptor } from './secret.interceptor';
import { AuthorizeInterceptor } from '@core/authorize.interceptor';
import { HomeComponent } from './home/home.component';



registerLocaleData(localeFr, 'fr');

export function init_auth(configService: ConfigService){
  //console.log(init_auth)
  return () =>configService.load()
}



export const httpLoaderFactory = (configService:ConfigService)=>{
  const config$ = configService.load()
  return new StsConfigHttpLoader( config$)


}

@NgModule({
  declarations:[
    //...fromComponents.components,
     FooterComponent, ImprintComponent, LegalNoticesComponent, PrivacyPolicyComponent, SalesTermsComponent, LoginMenuComponent,NavMenuComponent,UnauthorizedComponent,
     LoginComponent, LogoutComponent, HomeComponent
   ],
   providers:[

    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: HTTP_INTERCEPTORS, useClass: SecretInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    ConfigService,
    OidcConfigService
    ,

    {
     provide:APP_INITIALIZER,
     useFactory:init_auth,
     deps:[ConfigService, OidcConfigService],
     multi:true,
    },

   ],
   imports:[
     BrowserModule,
     HttpClientModule,
     RouterModule,
     ToastrModule.forRoot(),
     SharedModule,
     BrowserAnimationsModule,
     NgbModule,
     CommonModule,

     AuthModule.forRoot({
       loader:{
         provide: StsConfigLoader,
         useFactory:httpLoaderFactory,
         deps:[ConfigService]
       }
     }),
     NgxPermissionsModule.forRoot()
   ],
   exports:[
     NavMenuComponent, FooterComponent, LoginMenuComponent
   ]

})

export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
