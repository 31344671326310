<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate>
  <div class="panel">
    <div class="section-title">Créer une demande d'intervention</div>
    <div class="section-subtitle">Informations de contact</div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputFirstName">Prénom</label>
        <input type="text" class="form-control" id="inputFirstName" formControlName="firstName" required [ngClass]="{ 'is-invalid': firstName.invalid && !firstName.pristine }">
        <div *ngIf="firstName.invalid && !firstName.pristine" class="invalid-feedback">
          <span *ngIf="firstName.errors.required">
            Le prénom est obligatoire
          </span>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="intputName">Nom</label>
        <input type="text" class="form-control" id="intputName" formControlName="lastName" required [ngClass]="{ 'is-invalid': lastName.invalid && !lastName.pristine }">
        <div *ngIf="lastName.invalid && !lastName.pristine" class="invalid-feedback">
          <span *ngIf="lastName.errors.required">
            Le nom est obligatoire
          </span>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="inputAddress">Adresse</label>
      <input type="text" class="form-control" id="inputAddress" formControlName="address" required [ngClass]="{ 'is-invalid': address.invalid && !address.pristine }">
      <div *ngIf="address.invalid && !address.pristine" class="invalid-feedback">
        <span *ngIf="address.errors.required">
          L'adresse est obligatoire
        </span>
      </div>
    </div>
    <div class="form-group">
      <label for="inputAddress2">Addresse 2</label>
      <input type="text" class="form-control" id="inputAddress2" formControlName="address2">
    </div>
    <div class="form-row">
      <div class="form-group col-md-3">
        <label for="inputZip">Code postal</label>
        <input type="text" class="form-control" id="inputZip" formControlName="zip" required [ngClass]="{ 'is-invalid': zip.invalid && !zip.pristine }">
        <div *ngIf="address.invalid && !zip.pristine" class="invalid-feedback">
          <span *ngIf="zip.errors.required">
            Le code postal est obligatoire
          </span>
          <span *ngIf="zip.errors.pattern">
            Le code postal n'est pas valide
          </span>
        </div>
      </div>

      <div class="form-group col-md-9">
        <label for="inputCity">Ville</label>
        <input type="text" class="form-control" id="inputCity" formControlName="city" required [ngClass]="{ 'is-invalid': city.invalid && !city.pristine }">
        <div *ngIf="city.invalid && !city.pristine" class="invalid-feedback">
          <span *ngIf="city.errors.required">
            Le téléphone est obligatoire
          </span>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="inputPhone">Téléphone</label>
        <input type="text" class="form-control" id="inputPhone" formControlName="phone" required [ngClass]="{ 'is-invalid': phone.invalid && !phone.pristine }">
        <div *ngIf="phone.invalid && !phone.pristine" class="invalid-feedback">
          <span *ngIf="phone.errors.required">
            Le téléphone est obligatoire
          </span>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="inputEmail">Email</label>
        <input type="text" class="form-control" id="inputEmail" formControlName="email" required [ngClass]="{ 'is-invalid': email.invalid && !email.pristine }">
        <div *ngIf="email.invalid && !email.pristine" class="invalid-feedback">
          <span *ngIf="email.errors.required">
            L'email est obligatoire
          </span>
          <span *ngIf="email.errors.email">
            L'email n'est pas valide
          </span>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="inputComments">Commentaire</label>
      <textarea type="text" class="form-control" id="inputComments" formControlName="comments" rows="3"></textarea>
    </div>
  </div>
    <div class="clear-fix mt-1">
      <a class="btn btn-light float-left" (click)="previousStep()">Etape précédente</a>
      <button type="submit" class="btn btn-primary float-right" [disabled]="!formGroup.valid">Finaliser la demande</button>
    </div>

</form>

