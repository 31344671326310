
<div *ngIf="isAuthenticated">
  Veuillez vous connecter pour continuer ...

</div>















