import { Component, OnInit } from '@angular/core';
import { Department } from '../models/city';
import { Observable } from 'rxjs';
import { AvailabilityService } from '../services/availability.service';
import { UrlService } from '@core/services';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-postcode-list',
  templateUrl: './postcode-list.component.html',
  styleUrls: ['./postcode-list.component.scss']
})
export class PostcodeListComponent implements OnInit {
  departments$: Observable<Department[]>;

  adminUrl$: Observable<string>;

  constructor(private availabilitySevice: AvailabilityService, private urlService: UrlService) { }

  ngOnInit(): void {
    this.departments$ = this.availabilitySevice.getPostCodes().pipe(shareReplay(1));

    this.adminUrl$ = this.urlService.getCubeUrl().pipe(
      map(url => url + '/Administration')
    );

    // update when post codes modified
    this.availabilitySevice.postCodesUpdated.subscribe(_ => this.departments$ = this.availabilitySevice.getPostCodes().pipe(shareReplay(1)))
  }

}
