import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { JobType } from '../models/job-type.model';
import { Router, ActivatedRoute } from '@angular/router';
import { FormDataService } from '../services/form-data.service';
import { InterventionService } from '../services/intervention.service';

@Component({
  selector: 'app-job-selector',
  templateUrl: './job-selector.component.html',
  styleUrls: ['./job-selector.component.scss']
})
export class JobSelectorComponent implements OnInit {

  jobTypes$: Observable<JobType[]>;
  jobSubTypes: JobType[] = [];
  formGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private interventionService: InterventionService,
    private router: Router, private route: ActivatedRoute, private formDataService: FormDataService) { }

  ngOnInit() {

    this.formDataService.resetFormData();

    this.jobTypes$ = this.interventionService.jobTypes;

    this.formGroup = this.formBuilder.group({
      type: [null, [Validators.required]],
      subType: [{value: null, disabled: true}, [Validators.required]]
    });
  }

  get type() {
    return this.formGroup.get('type');
  }

  get subType() {
    return this.formGroup.get('subType');
  }

  onSelectType() {
    this.jobSubTypes = this.type.value.subTypes;
    this.subType.setValue(null);
    this.subType.enable();
  }

  onSelectSubType() {
    const selectedType = this.type.value;
    const selectedSubType = this.subType.value;
    this.formDataService.setSelectedJobType(selectedType, selectedSubType);
    this.router.navigate(['../question'], { relativeTo: this.route });
  }
}
