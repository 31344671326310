

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { authRoutes } from '@core/auth.routes';

import { ImprintComponent } from '@core/components/imprint/imprint.component';
import { LegalNoticesComponent } from '@core/components/legal-notices/legal-notices.component';
import { PrivacyPolicyComponent } from '@core/components/privacy-policy/privacy-policy.component';
import { SalesTermsComponent } from '@core/components/sales-terms/sales-terms.component';
import { LogoutComponent } from './core/components/logout/logout.component';
import { HomeComponent } from './core/home/home.component';

const appRoutes: Routes = [

 //{ path: '', redirectTo: 'availability', pathMatch: 'full' },
  { path: '', redirectTo: 'availability', pathMatch: 'full' },

   {path: 'availability',
   loadChildren:()=>import('./availability/availability.module').then(m =>m.AvailabilityModule),
  },
  {path: 'interventions',
   loadChildren:()=>import('./interventions/interventions.module').then(m =>m.InterventionsModule),
  },
   {
    path: 'home',
    component: HomeComponent,
  },


  {
    path: 'PolitiqueConfidentialite',
    component: PrivacyPolicyComponent,
  },

  {
    path: 'Imprint',
    component: ImprintComponent,
  },
  {
    path: 'MentionsLegales',
    component: LegalNoticesComponent,
  },
  {
    path: 'ConditionsGeneralesVente',
    component: SalesTermsComponent,
  },
  {
    path: 'authentication/logout-callback',
    component: LogoutComponent
  }

  //{ path: '', redirectTo: 'home', pathMatch: 'full' },


];

@NgModule({
  imports: [
   // RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
   RouterModule.forRoot(
    appRoutes.concat(authRoutes),
    {
      scrollPositionRestoration:'enabled',
      relativeLinkResolution:'legacy',
      onSameUrlNavigation:'reload',
      preloadingStrategy:PreloadAllModules
    }
   )

  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
