import { Skill } from './../models/skill';
import { PatmItem } from './../models/patm-item';
import { Injectable, Inject, EventEmitter } from '@angular/core';
import { SelectedDate, DateType } from '../models/selected-date';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { datepickerAnimation } from 'ngx-bootstrap/datepicker/datepicker-animations';
import { Settings } from '../models/settings';
import { City, Department } from '../models/city';
import { Intervention } from '../models/intervention';
import { InterventionUpdate } from '../models/intervention-update';
import { InterventionInfo, Reason } from '../models/intervention-info';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {

  public postCodesUpdated = new EventEmitter<boolean>();
  public skillsUpdated = new EventEmitter<boolean>();

  private _selectedDates = new BehaviorSubject<SelectedDate[]>([]);
  private _myInterventions = new BehaviorSubject<Intervention[]>([]);
  private _myHistoInterventions = new BehaviorSubject<Intervention[]>([]);

  readonly selectedDates$ = this._selectedDates.asObservable();
  readonly myInterventions$ = this._myInterventions.asObservable();
  readonly myHistoInterventions$ = this._myHistoInterventions.asObservable();

  isLoading = new Subject<boolean>();

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getAvailabilities() {
    return this.getAvailabilityData().pipe(
      tap(res => {
        //this.isLoading.next(false);
        this._selectedDates.next(res);
      })
    )
    // .subscribe(res => {
    //   this.isLoading.next(false);
    //   this._selectedDates.next(res);
    // });
  }

  getAvailabilityData() {
    return this.http.get<SelectedDate[]>(this.baseUrl + 'api/partner/slots')
      .pipe(
        map((data) => data.map((item) => {
          item.date = new Date(item.date);
          return item;
        })
        ));
  }

  setAvailabilities(selectedDates: SelectedDate[]) {
    const filteredDates = selectedDates.filter(date => date.type !== DateType.NONE);

    return this.http.post(this.baseUrl + 'api/partner/slots', filteredDates).pipe(
      // refresh interventions
      // tap(_ => this.getAvailabilities())
    )
    // .subscribe();
  }

  getSettings(): Observable<Settings> {
    return this.http.get<Settings>(this.baseUrl + 'api/partner/settings');
  }

  getSkills(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + 'api/partner/skills');
  }

  getAllSkills(): Observable<Skill[]> {
    return this.http.get<Skill[]>(this.baseUrl + 'api/partner/allSkills');
  }

  updateSkills(skills: number[]) {
    return this.http.post(this.baseUrl + 'api/partner/skills', skills);
  }

  getPostCodes(): Observable<Department[]> {
    return this.http.get<Department[]>(this.baseUrl + 'api/partner/postcodes');
  }

  updatePostCodes(cities : City[]) {
    return this.http.post(this.baseUrl + 'api/partner/postcodes', cities);
  }

  setSettings(settings: Settings) {
    return this.http.post(this.baseUrl + 'api/partner/settings', settings);
  }


  getSundays(a?: Date): Date[] {
    const sundays = [];

    let current = new Date(); // Current date (default)
    if (a) {
      if (a.constructor === Date) { // There's input date
        current = a; // Input date
      }
    }

    const date = new Date(current.getFullYear(), current.getMonth(), 1); // First date of current month

    let addend = date.getDay();
    if (addend > 0) { // First date of month is NOT Sunday
      addend = 7 - addend;
    }
    date.setDate(date.getDate() + addend); // Important --> first Sunday of current month

    while (date.getFullYear() === current.getFullYear()) { //  Loop for next 6 months
      sundays.push(new Date(date)); // Store Sunday date
      date.setDate(date.getDate() + 7); // Increment weekly (7 days)
    }

    return sundays;
  }

  getAllDays(a?: Date, fromToday: boolean = false): Date[] {
    const days = [];

    let current = new Date(); // Current date (default)
    if (a) {
      if (a.constructor === Date) { // There's input date
        current = a; // Input date
      }
    }

    const date = fromToday ?
      new Date(current.getFullYear(), current.getMonth(), current.getDate()) :
      new Date(current.getFullYear(), current.getMonth(), 1); // First date of current month

    while (date.getMonth() === current.getMonth()) { //  Loop only for current month
      days.push(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))); // Store date
      date.setDate(date.getDate() + 1); // Increment daily
    }

    return days;
  }

  getMyInterventionsAvailables() {
    return this.http.get<Intervention[]>(this.baseUrl + 'api/intervention/availables')
      .subscribe(res => {
        this.isLoading.next(false);
        this._myInterventions.next(res);
      });
  }

  processIntervention(interventionId: number) {
    return this.http.post<number>(this.baseUrl + 'api/intervention/' + interventionId + '/process', {}).pipe(
    );
  }

  processInterventionCommanditaire(interventionCommanditaireId: number, reasonId: number, hasInstalledThermostat: string, typeThermostat: string, selectedReasonNonInstallation: string, usageMixte: boolean, majoriteHabitation: boolean, remplacementRequis: boolean) {
    return this.http.post<number>(this.baseUrl + 'api/partner/process', { interventionCommanditaireId, reasonId, hasInstalledThermostat, typeThermostat, selectedReasonNonInstallation, usageMixte, majoriteHabitation, remplacementRequis }).pipe(
    );
  }

  uploadMaintenanceCertificate(formData: FormData) {
    return this.http.post(this.baseUrl + 'api/partner/uploadMaintenanceCertificate', formData);
  }

  updateInterventionAfterAcceptanceOrRefusal(data: InterventionUpdate) {
    return this.http.post<InterventionInfo>(this.baseUrl + 'api/partner/updateInterventionAfterAcceptanceOrRefusal', data).pipe(
      // refresh interventions
      tap(_ => this.getMyInterventionsAvailables())
    );
  }

  acceptOrRefusalIntervention(data: InterventionUpdate) {
    return this.http.post(this.baseUrl + 'api/intervention/acceptOrRefusalIntervention', data).pipe(
      // refresh interventions
      tap(_ => this.getMyInterventionsAvailables())
    );
  }


  getReasons(interventionId: number, realized: boolean, hasAnomaly: boolean) {
    return this.http.get<Reason[]>(this.baseUrl +
      `api/intervention/${interventionId}/reasons?realized=${realized}&hasAnomaly=${hasAnomaly}`);
  }

  getHistory() {
    return this.http.get<Intervention[]>(this.baseUrl + 'api/intervention/historyByRole')
      .subscribe(res => {
        this.isLoading.next(false);
        this._myHistoInterventions.next(res);
      });
  }

  getMaintenanceCertificate(idIntervention: number) {
    return this.http.get(this.baseUrl + 'api/partner/getMaintenanceCertificate?idIntervention=' + idIntervention);
  }

  getPatms() {
    return this.http.get<PatmItem[]>(this.baseUrl + 'api/partner/patms');
  }
}
