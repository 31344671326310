import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Intervention } from '../models/intervention';
import { AvailabilityService } from '../services/availability.service';
import { InterventionUpdate } from '../models/intervention-update';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@core/services';
import { LoaderService } from '@shared/services/loader.service';

@Component({
  selector: 'app-intervention-modal',
  templateUrl: './intervention-modal.component.html',
  styleUrls: ['./intervention-modal.component.scss']
})
export class InterventionModalComponent implements OnInit {

  intervention: Intervention;
  formGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, public bsModalRef: BsModalRef, private availabilityService: AvailabilityService, private notifyService: NotificationService, private loaderService: LoaderService) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      date: [''],
    });
  }

  get date() {
    return this.formGroup.get('date');
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    const interventionForm = this.formGroup.value as Intervention;
    interventionForm.id = this.intervention.id;
    this.takeIntervention(interventionForm)
  }

  takeIntervention(interventionUpdate) {
    const data = {
      idIntervention: interventionUpdate.id,
      accepted: true,
      planifiedDate: interventionUpdate.date == "" ? null : interventionUpdate.date
    } as InterventionUpdate;

    this.loaderService.show();

    this.availabilityService.acceptOrRefusalIntervention(data).subscribe(() => {
      this.loaderService.hide();
      this.notifyService.showSuccess('Intervention traitée', 'Message');
      this.bsModalRef.hide();
    });
  }
}
