import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Intervention } from '../models/intervention';
import { InterventionService } from '../services/intervention.service';
import { NotificationService } from '@core/services';
import { InterventionModalComponent } from '../intervention-modal/intervention-modal.component';

@Component({
  selector: 'app-intervention-history',
  templateUrl: './intervention-history.component.html',
  styleUrls: ['./intervention-history.component.scss']
})
export class InterventionHistoryComponent implements OnInit {

  bsModalRef: BsModalRef;

  interventions$: Observable<Intervention[]>;

  constructor(private interventionService: InterventionService, private modalService: BsModalService,
    private notifyService: NotificationService) { }

  ngOnInit() {
    this.interventions$ = this.interventionService.getInterventionHistory();
  }

  openDetail(intervention: Intervention) {
    const initialState = {
      intervention: intervention,
      isHistory: true
    };
    this.bsModalRef = this.modalService.show(InterventionModalComponent, {initialState});
  }
}
