import { Component, OnInit, Injectable } from '@angular/core';
import { CalendarView, DAYS_OF_WEEK, CalendarDateFormatter, DateFormatterParams, CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent, CalendarEventTitleFormatter } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { Subject, Observable } from 'rxjs';
import { Intervention } from '../models/intervention';
import { PlanningService } from '../services/planning.service';
import { AvailabilityService } from '../services/availability.service';
import { map, tap } from 'rxjs/operators';
import { isEqual, setHours, startOfDay, startOfHour, addMinutes } from 'date-fns';
import { DateType } from '../models/selected-date';
import { InterventionEvent } from 'app/interventions/models/intervention-event';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // you can override any of the methods defined in the parent class
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    //return new DatePipe(locale).transform(date, 'a', locale);
    return date.getHours() < 12 ? 'Matin' : 'Après-midi';
  }
}

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  constructor() {
    super();
  }

  // you can override any of the methods defined in the parent class
  week(event: CalendarEvent): string {
    return `${event.meta.intervention.clientName}<img src="${event.meta.intervention.logoStatus}">
      &nbsp/&nbsp${event.meta.intervention.zipCode}<br>
      <b>${event.title}</b>`;
      // <img src="${event.meta.intervention.logo}"`;
  }

  weekTooltip(event: CalendarEvent, title: string): string {
    return `<strong>${event.title}</strong><br>
    ${event.meta.intervention.address}<br>
    ${event.meta.intervention.zipCode}&nbsp${event.meta.intervention.city}`;
  }
}

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ],
})
export class PlanningComponent implements OnInit {
  CalendarView = CalendarView;

  view: CalendarView = CalendarView.Week;

  viewDate: Date = new Date();

  locale = 'fr';
   // exclude weekends
  excludeDays: number[] = [0];
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  hourSegments = 6;
  segmentDuration = 60 / this.hourSegments;


  events$: Observable<CalendarEvent<{ intervention: InterventionEvent }>[]>;

  refresh: Subject<any> = new Subject();

  selectedIntervention$: Observable<Intervention>;
  availableSlots: Date[] = [];

  constructor(private planningService: PlanningService, private availabilityService: AvailabilityService) { }

  ngOnInit(): void {
    this.selectedIntervention$ = this.planningService.selectedIntervention$;

    this.availabilityService.getAvailabilityData()
    .pipe(
      map(res => res.map(item => {
        return (item.type === DateType.AM) ?
          setHours(item.date, 11) : // morning slots : 11AM
          setHours(item.date, 12); // afternoon slots: 12AM
      }))
    )
    .subscribe(res => {
      this.availableSlots = res;
      this.refresh.next();
    });

    this.planningService.getPlannedInterventions();
    this.events$ = this.planningService.plannedInterventions$
      .pipe(
        map(results => {
          return results.map((intervention: InterventionEvent) => {
            return {
              title: intervention.name,
              start: new Date(intervention.date),
              meta: {
                intervention
              },
              color: {primary: '#AED6E8', secondary: 'white'},
              cssClass: 'event-title'
            } as CalendarEvent;
          });
        }),
        tap(results => {
          const groups = this.groupByDate(results);
          groups.forEach((slot: CalendarEvent[]) => {
            slot.forEach((event, index) => {
              event.start.setMinutes((index % this.hourSegments) * this.segmentDuration); // shift each intervention of 10 min
              event.end = addMinutes(event.start, this.segmentDuration);
            });
          });
        })
      );


  }

  setView(view: CalendarView) {
    this.view = view;
  }

  changeDay(date: Date) {
    this.viewDate = date;
    this.view = CalendarView.Week;
  }

  refreshInterventions() {
    this.planningService.getPlannedInterventions();
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
    const id = event.meta.intervention.id;
    this.planningService.getInterventionDetail(id);
    console.log('Event clicked', event);
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach((day) => {
      if (this.availableSlots.findIndex(item => isEqual(startOfDay(item), day.date)) >= 0) {
        day.cssClass = 'available';
      }
    });
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {
        hour.segments.forEach((segment) => {
          if (this.availableSlots.findIndex(item => isEqual(item, startOfHour(segment.date))) >= 0) {
            segment.cssClass = 'available';
          }
        });
      });
    });
  }

  groupByDate(events: CalendarEvent[]) {
    const groups = events.reduce(function (acc, obj) {
      const key = obj.start.getTime();
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    return Object.values(groups);
  }
}



