import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Intervention } from '../models/intervention';
import { Subject, InteropObservable } from 'rxjs';

@Component({
  selector: 'app-intervention-modal',
  templateUrl: './intervention-modal.component.html',
  styleUrls: ['./intervention-modal.component.scss']
})
export class InterventionModalComponent implements OnInit {

  intervention: Intervention;
  isHistory = false;

  public onProcessed: Subject<Intervention>;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onProcessed = new Subject();
  }

  accept() {
    this.onProcessed.next(this.intervention);
    this.bsModalRef.hide();
  }
}
