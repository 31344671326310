<div class="section-subtitle">
  {{title}}
</div>
<div class="table-responsive-md">
  <table class="table table-striped basic-table">
    <thead>
    <tr>
      <th scope="col">Client</th>
      <th scope="col">Addresse</th>
      <th scope="col">Date</th>
      <th scope="col">Type</th>
      <th scope="col"></th>
      <!-- <th scope="col">Pris en charge</th> -->
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let intervention of interventions$ | async">
      <td>{{intervention.contact.firstName}} {{intervention.contact.lastName}}</td>
      <td>{{intervention.contact.address}}, {{intervention.contact.city}}</td>
      <td>{{intervention.date | date:'short' }}</td>
      <td>{{intervention.name}}</td>
      <td><button class="btn btn-light" (click)="openDetail(intervention)">Détail</button></td>
      <!-- <td>
        <span *ngIf="intervention.isAssigned; else elseBlock">Oui</span>
        <ng-template #elseBlock>
          <button class="btn btn-primary" (click)="acceptIntervention(intervention)">Accepter</button>
        </ng-template>
      </td> -->
    </tr>
    </tbody>
  </table>
</div>
