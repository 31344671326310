<footer>
  <div class="top-footer"></div>
  <div class="container-fluid footer-elm">
    <div class="row">
      <div
        class="col-md-3 col-xs-12 col-sm-12"
        style="text-align: center; margin-top: 5px"
      >
        <span>elm.leblanc S.A.S</span>
      </div>
      <div class="col-md-3 col-xs-6 col-sm-6" style="margin-top: 5px">
        A propos
        <ul style="margin-top: 5px">
          <li>
            <a href="/Imprint">Imprint</a>
          </li>
          <li>
            <a href="/MentionsLegales">Mentions légales</a>
          </li>
          <li>
            <a href="/PolitiqueConfidentialite">Politique de confidentialité</a>
          </li>
          <li>
            <a href="/ConditionsGeneralesVente">Conditions générales de vente</a>
          </li>
          <li>
            <a style="cursor:pointer;" data-show-privacy-settings="" class="privacy-settings-modal" title="Paramètres de cookies">Paramètres de cookies</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3 col-xs-6 col-sm-6" style="margin-top: 5px">
        Nos sites
        <ul style="margin-top: 5px">
          <li>
            <a href="https://www.elmleblanc.fr" target="_blank"
              >www.elmleblanc.fr</a
            >
          </li>
          <li>
            <a href="https://www.bosch-thermotechnology.com/" target="_blank"
              >www.bosch-chauffage.fr</a
            >
          </li>
          <li>
            <a href="https://www.elmlecube.fr" target="_blank"
              >www.elmlecube.fr</a
            >
          </li>
          <li>
            <a href="http://www.bosch-industrial.fr" target="_blank"
              >www.bosch-industrial.fr</a
            >
          </li>
        </ul>
      </div>
      <div class="col-md-3">
        <div class="copyright">
          © elm.leblanc SAS 2022, tous droits réservés
        </div>
        <div class="copyrightXS">
          © elm.leblanc SAS 2022, tous droits réservés
        </div>
      </div>
    </div>
  </div>
</footer>
