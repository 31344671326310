<span class="link" (click)="openModal(updatePostCodesTemplate)">(modifier)</span>

<ng-template #updatePostCodesTemplate>
    <h1>Mes zones d'intervention</h1>

    <ng-container *ngIf="departmentsWithCities$ | async as departments; else loading">
        <div class="overflow-auto" style="max-height: 70vh;">
            <div *ngFor="let dep of departments; let i = index">
                <div class="d-flex flex-row align-items-center">
                    <div class="p-2" (click)="isCollapsed[i] = !isCollapsed[i]">
                        <div class="triangle" [ngClass]="{'collapsed': isCollapsed[i]}"></div>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" [ngClass]="checkBoxStyle(dep)" type="checkbox" [id]="dep.code"
                            [checked]="isCheckedDepartment(dep)" (change)="checkOrUncheckDepartment(dep, $event)">
                        <label (click)="isCollapsed[i] = !isCollapsed[i]" class="form-check-label">{{dep.code}} -
                            {{dep.nom}}</label>
                    </div>
                </div>
                <ng-container *ngIf="!isCollapsed[i]">
                    <ng-container *ngFor="let city of dep.cities">
                        <div *ngFor="let postCode of city.codesPostaux" class="ml-5 form-check">
                            <input class="form-check-input" type="checkbox" [id]="city.code+'_'+postCode"
                                [checked]="isPostCodeChecked(city.code, postCode)"
                                (change)="checkOrUncheckPostCode(city.code, postCode, $event)">
                            <label class="form-check-label" [for]="city.code+'_'+postCode">{{city.nom}}
                                ({{postCode}})</label>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="mt-2 d-flex flex-row-reverse">
            <button *ngIf="!isSaving" class="btn btn-primary" (click)="onSubmit()">Enregistrer</button>
            <button *ngIf="isSaving" class="btn btn-primary" disabled><span class="spinner-border spinner-border-sm"
                    role="status" aria-hidden="true"></span> Merci de patienter...</button>
        </div>
    </ng-container>
</ng-template>

<ng-template #loading>
    <div class="col-12 my-5">
        <mat-spinner style="left: unset; transform: unset;" class="spinner mx-auto"></mat-spinner>
    </div>
</ng-template>