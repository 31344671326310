import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@core/services';
import { Intervention } from '../models/intervention';
import { Observable } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AvailabilityService } from '../services/availability.service';
import { HistoryInfoModalComponent } from '../history-info-modal/history-info-modal.component';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  bsModalRef: BsModalRef;

  interventions$: Observable<Intervention[]>;
  isLoading = true;

  constructor(private availabilityService: AvailabilityService, private modalService: BsModalService,
    private notifyService: NotificationService) { }

  ngOnInit() {
    this.availabilityService.getHistory();

    //loader
    this.availabilityService.isLoading.subscribe(res => this.isLoading = res)

    //filter interventions based on input
    this.interventions$ = this.availabilityService.myHistoInterventions$;  
  }

  openDetail(intervention: Intervention) {
    const initialState = {
      intervention: intervention,
      isHistory: true
    };
    this.bsModalRef = this.modalService.show(HistoryInfoModalComponent, {initialState});
  }

}
