import { Injectable, Inject } from '@angular/core';
import { Intervention } from '../models/intervention';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { InterventionEvent } from 'app/interventions/models/intervention-event';

@Injectable({
  providedIn: 'root'
})
export class PlanningService {

  private _selectedIntervention = new Subject<Intervention>();
  private _plannedInterventions = new Subject<InterventionEvent[]>();

  readonly selectedIntervention$ = this._selectedIntervention.asObservable();
  readonly plannedInterventions$ = this._plannedInterventions.asObservable();

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getInterventionDetail(id: number) {
    return this.http.get<Intervention>(this.baseUrl + 'api/intervention/planned/' + id)
      .subscribe(res => {
        this._selectedIntervention.next(res);
      });
  }

  getPlannedInterventions() {
    return this.http.get<InterventionEvent[]>(this.baseUrl + 'api/intervention/planned')
    .subscribe(res => {
      this._plannedInterventions.next(res);
    });
  }
}
