import { Component, OnInit } from '@angular/core';
import { AvailabilityService } from '../services/availability.service';
import { Observable } from 'rxjs';
import { Intervention } from '../models/intervention';
import { shareReplay } from 'rxjs/operators';
@Component({
  selector: 'app-interventions',
  templateUrl: './interventions.component.html',
  styleUrls: ['./interventions.component.scss']
})
export class InterventionsComponent implements OnInit {

  constructor(private availabilityService: AvailabilityService) { }

  interventions$: Observable<Intervention[]>;
  isLoading = true;

  ngOnInit(): void {
    this.availabilityService.getMyInterventionsAvailables();

    //loader
    this.availabilityService.isLoading.subscribe(res => this.isLoading = res)

    //filter interventions based on input
    this.interventions$ = this.availabilityService.myInterventions$.pipe(shareReplay(1));
  }
}
