<div class="section-subtitle">
  Mes zones d'intervention
  <!-- <a [href]="(adminUrl$ | async)">(modifier)</a> -->
  <ng-container *ngIf="departments$ | async as departments">
    <app-update-postcodes [entryData]="departments"></app-update-postcodes>
  </ng-container>
</div>
<p><i>Liste des communes</i></p>
<div class="anyClass">
  <dl class="row">
    <div *ngIf="departments$ | async; else loading;">
      <ng-template ngFor let-department [ngForOf]="departments$ | async">
        <dt class= col-sm-3>{{department.code}}</dt>
        <dd class="col-sm-9">
          <ul class="list-unstyled">
            <!-- <li *ngFor="let postCode of department.postCodes">{{postCode}}</li> -->
            <li *ngFor="let city of department.cities">{{city.postCode}}</li>
          </ul>
        </dd>
      </ng-template>
    </div>
  </dl>
</div>

<ng-template #loading>
  <div class="text-center mt-5">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>