<div class="section-subtitle">
  Expertise
  <!-- <a [href]="(adminUrl$ | async)">(modifier)</a> -->
  <ng-container *ngIf="skills$ | async as skills">
    <app-update-skills [entrySkills]="skills"></app-update-skills>
  </ng-container>
</div>
<p><i>Vos compétences</i></p>
<div *ngIf="skills$ | async as skills; else loadingSkills;">
  <p>{{skills.join(', ')}}</p>
</div>

<ng-template #loadingSkills>
  <div class="text-center mt-5">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
