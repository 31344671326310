import { AvailabilityService } from './../services/availability.service';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { CityGeo, GeoApiService, DepartmentGeo } from './../../core/services/geo-api.service';
import { Observable, zip } from 'rxjs';
import { Department, City } from './../models/city';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-update-postcodes',
  templateUrl: './update-postcodes.component.html',
  styleUrls: ['./update-postcodes.component.scss']
})
export class UpdatePostcodesComponent implements OnInit {

  modalRef: BsModalRef;

  @Input() entryData: Department[]
  departmentsWithCities$: Observable<DepartmentGeo[]>

  selectedCities: City[]
  isCollapsed: boolean[]

  isSaving = false;

  constructor(private modalService: BsModalService, private geoApiService: GeoApiService, private availabilityService: AvailabilityService) { }

  ngOnInit(): void {
    // init selected postCodes with entry data
    this.selectedCities = this.entryData.reduce((postCodes, dep) => [...postCodes, ...dep.cities], []);

    this.departmentsWithCities$ = zip(this.geoApiService.getDepartments(), this.geoApiService.getCities()).pipe(
      map(([deps, cities]) => deps.map(dep => {
        // add cities to department
        dep.cities = cities.filter(c => c.codeDepartement == dep.code)
        return dep;
      })),
      tap(deps => {
        // init collapse array
        this.isCollapsed = deps.map(dep => dep.cities.some(c => this.selectedCities.some(sc => sc.cityCode == c.code && c.codesPostaux.includes(sc.postCode))) ? false : true);
      })
    )
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  isPostCodeChecked(cityCode: string, postCode: string) {
    return this.selectedCities.some(c => c.cityCode == cityCode && c.postCode == postCode)
  }

  // called when city is checked or unchecked
  checkOrUncheckPostCode(cityCode: string, postCode: string, event) {
    let isChecked = event.target.checked
    let elm = { cityCode: cityCode, postCode: postCode } as City

    if (isChecked) {
      // add postcodes to selected
      this.selectedCities.push(elm)
    } else {
      // remove postCodes from selected
      const index = this.getIndexFromSelected(elm);
      if (index > -1) {
        this.selectedCities.splice(index, 1);
      }
    }
  }

  isCheckedDepartment(department: DepartmentGeo) {
    return department.cities.every(c => c.codesPostaux.every(pc => this.selectedCities.some(sc => sc.cityCode == c.code && sc.postCode == pc)))
  }

  checkOrUncheckDepartment(department: DepartmentGeo, event) {
    let isChecked = event.target.checked

    // get all cities of department
    let depCities: City[] = department.cities.reduce((postCodes, city) => [...postCodes, ...city.codesPostaux.map(pc => {
      return { cityCode: city.code, postCode: pc } as City
    })], []);

    if (isChecked) {
      // add postcodes to selected
      this.selectedCities = this.selectedCities.concat(depCities)
    } else {
      // remove postCodes from selected
      this.selectedCities = this.selectedCities.filter(sc => !depCities.some(dc => dc.cityCode == sc.cityCode && dc.postCode == sc.postCode))
    }
  }

  onSubmit() {
    this.isSaving = true
    // only send new postCodes
    this.availabilityService.updatePostCodes(this.selectedCities)
      .subscribe(res => {
        this.isSaving = false
        this.modalRef.hide();
        this.availabilityService.postCodesUpdated.emit(true);
      });
  }

  getIndexFromSelected(city: City) {
    for (let i = 0; i < this.selectedCities.length; i++) {
      if (this.selectedCities[i].cityCode == city.cityCode && this.selectedCities[i].postCode == city.postCode)
        return i
    }
    return -1
  }

  checkBoxStyle(department : DepartmentGeo){
    if (department.cities.some(c => this.selectedCities.some(sc => sc.cityCode == c.code && c.codesPostaux.includes(sc.postCode)))){
      return 'hasPostCode';
    }
    return
  }
}
