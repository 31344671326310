import { AdminService } from './../services/admin.service';
import { switchMap, tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './../../core/services/user.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit {

  isAdminView: boolean;
  setOrganization$: Observable<any>;

  hide_ = false;

  constructor(private router: Router, private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit(): void {
    
    this.isAdminView = this.router.url.includes('/admin');

    if (this.isAdminView) {
      let idValue = this.router.url.split('/');
      let id = idValue[idValue.length - 1];
       this.setOrganization$ = this.userService.setOrganizationId(parseInt(id));

      //this.setOrganization$ = this.route.params.pipe(
      //  switchMap(params => this.userService.setOrganizationId(params.id)),
      //  tap(res => console.log('result from setOrganization', res))
      //)
    } else {
      this.setOrganization$ = this.userService.deleteOrganizationId();
    }
  }
}
