<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate>
  <fieldset>
    <div class="section-subtitle">Créneaux matin</div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="inputMorningMax">Nombre d'interventions max</label>
      <div class="col-sm-7">
        <input type="text" class="form-control" id="inputMorningMax" formControlName="morningMax" required
          [ngClass]="{ 'is-invalid': submitted && morningMax.invalid }">
        <div *ngIf="submitted && morningMax.invalid" class="invalid-feedback">
          <span *ngIf="morningMax.errors.required">
            La valeur est obligatoire
          </span>
          <span *ngIf="morningMax.errors.pattern">
            La valeur n'est pas valide
          </span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label">Plage horaire communiquée au client</label>
      <div class="col-sm-7">
        <div class="form-row">
          <div class="col">
            <timepicker [formControlName]="'morningStart'" required></timepicker>
            <div *ngIf="submitted && morningStart.invalid" class="invalid-feedback d-block">
              <span>
                La valeur est invalide
              </span>
            </div>
          </div>
          <div class="col">
            <timepicker [formControlName]="'morningEnd'" required></timepicker>
            <div *ngIf="submitted && morningEnd.invalid" class="invalid-feedback d-block">
              <span>
                La valeur est invalide
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
  <fieldset>
    <div class="section-subtitle">Créneaux après-midi</div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="inputAfternoonMax">Nombre d'interventions max</label>
      <div class="col-sm-7">
        <input type="text" class="form-control" id="inputAfternoonMax" formControlName="afternoonMax" required
          [ngClass]="{ 'is-invalid': submitted && afternoonMax.invalid }">
        <div *ngIf="submitted && afternoonMax.invalid" class="invalid-feedback">
          <span *ngIf="afternoonMax.errors.required">
            La valeur est obligatoire
          </span>
          <span *ngIf="afternoonMax.errors.pattern">
            La valeur n'est pas valide
          </span>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label">Plage horaire communiquée au client</label>
      <div class="col-sm-7">
        <div class="form-row">
          <div class="col">
            <timepicker [formControlName]="'afternoonStart'" required></timepicker>
            <div *ngIf="submitted && afternoonStart.invalid" class="invalid-feedback d-block">
              <span>
                La valeur est obligatoire
              </span>
            </div>
          </div>
          <div class="col">
            <timepicker [formControlName]="'afternoonEnd'" required></timepicker>
            <div *ngIf="submitted && afternoonEnd.invalid" class="invalid-feedback d-block">
              <span>
                La valeur est obligatoire
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
  <fieldset class="mt-5">
    <p><i>Ces paramètres s'appliqueront à tous les créneaux matin / après-midi</i></p>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="inputBlockedDays">Délai minimum de notification d'une nouvelle
        intervention</label>
      <div class="col-sm-7">
        <div class="input-group">
          <input #blockedDays type="text" class="form-control" id="inputBlockedDays" formControlName="blockedDays" required
            [ngClass]="{ 'is-invalid': submitted && blockedDays.invalid }">
          <div class="input-group-append">
            <span class="input-group-text">jours</span>
          </div>
        </div>
        <div *ngIf="submitted && blockedDays.invalid" class="invalid-feedback d-block">
          <span *ngIf="blockedDays.errors.required">
            La valeur est obligatoire
          </span>
          <span *ngIf="blockedDays.errors.pattern">
            La valeur n'est pas valide
          </span>
        </div>
        <small class="form-text text-muted">
          <!-- C'est le délai minimum avant de planifier une intervention. Pas de nouvelle planification avant le  J = J + 5. -->
          Vous serez toujours prévenu d'une intervention au moins {{blockedDays.value}} jours à l'avance.
        </small>
      </div>

    </div>
    <div class="form-group row">
      <label class="col-sm-5 col-form-label" for="inputWeekMax">Nombre d'interventions max par semaine</label>
      <div class="col-sm-7">
        <input type="text" class="form-control" id="inputWeekMax" formControlName="weekMax" required
          [ngClass]="{ 'is-invalid': submitted && weekMax.invalid }">
        <div *ngIf="submitted && weekMax.invalid" class="invalid-feedback">
          <span *ngIf="weekMax.errors.required">
            La valeur est obligatoire
          </span>
          <span *ngIf="weekMax.errors.pattern">
            La valeur n'est pas valide
          </span>
        </div>
      </div>
    </div>
  </fieldset>
  <div class="clearfix">
    <button *ngIf="!isSaving" type="submit" class="btn btn-primary float-right">Enregistrer</button>
    <button *ngIf="isSaving" type="button" class="btn btn-primary float-right" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Enregistrement...
    </button>

  </div>
</form>