import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { QuestionService } from '../services/question.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Question, QuestionOption } from '../models/question';
import { FormDataService } from '../services/form-data.service';
import { JobType } from '../models/job-type.model';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  type: JobType;
  question$: Observable<Question>;

  constructor(private questionService: QuestionService, private formDataService: FormDataService,
    private router: Router, private route: ActivatedRoute) {
   }

  ngOnInit() {
    this.question$ = this.questionService.question;

    // get job type from service
    this.type = this.formDataService.getSelectedJobType();

    if (this.formDataService.getSelectedOptions() == null) {
      const subType = this.type.subTypes[0].id;
      this.questionService.getFirstQuestion(subType);
    } else {
      this.questionService.getLastQuestion();
    }
  }

  next(answer: QuestionOption) {
    if (!answer.nextQuestionId || answer.nextQuestionId === null) {
      this.questionService.addAnswer(answer);
      this.formDataService.setSelectedOptions(this.questionService.getAnswers());
      this.router.navigate(['../info'], { relativeTo: this.route });
    } else {
      this.questionService.getNextQuestion(answer);
    }
  }

  previous() {
    if (this.questionService.hasPreviousQuestion()) {
      this.questionService.getPreviousQuestion();
    } else {
      // back to previous form
      this.formDataService.resetSelectedJobType();
      this.router.navigate(['../job'], { relativeTo: this.route });
    }
  }
}
