import { AuthorizeGuard } from '@shared/guards/authorize.guard';

import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';


import {Routes } from '@angular/router'
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ApplicationPaths } from './api-authorization.constants';



export const authRoutes:Routes=[
  {path:ApplicationPaths.Unauthorized, component:UnauthorizedComponent},
  {path: ApplicationPaths.Login, component:LoginComponent },
  {path: ApplicationPaths.LogOutCallback, component:LogoutComponent },
 // {path: ApplicationPaths.LoginCallback, component:LoginComponent }
  // {
  //   path: 'protected',
  //   component: LoginComponent,
  //   canActivate: [AuthorizeGuard]
  // },
];
