<div class="row">
    <ng-container *ngIf="!isLoading; else loading">
      <div *ngFor="let intervention of interventions$ | async" class="col-sm-6">
        <app-intervention-card [intervention]="intervention"></app-intervention-card>
      </div>
      <div class="px-5" *ngIf="(interventions$ | async)?.length==0">Aucune intervention proposée</div>
    </ng-container>
</div>
<!-- <app-loader></app-loader> -->

<ng-template #loading>
  <div class="col-12 text-center mt-5">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</ng-template>
