import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormDataService } from '../services/form-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, throwError, Subject, of, Subscription } from 'rxjs';
import { ContactInfo } from '../models/contact-info';
import { InterventionService } from '../services/intervention.service';
import { Organisation } from '../models/organisation';
import { first, tap, map, mergeMap, switchMap, shareReplay } from 'rxjs/operators';
import { InterventionRequest } from '../models/intervention-request';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit, OnDestroy {

  partners$: Observable<Organisation[]>;
  interventionCreated$: Subscription;

  info: ContactInfo;
  message: string;
  interventionCreated = false;

  constructor(private formDataService: FormDataService, private interventionService: InterventionService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.info = this.formDataService.getPersonalInfo();
    const options = this.formDataService.getSelectedOptions();

    this.message = `Recherche de partenaire pour l'intervention autour de ${this.info.city} ...`;

    this.partners$ = this.interventionService.getMatchingOrganisations(options, this.info).pipe(shareReplay(1));

    this.interventionCreated$ = this.partners$.pipe(
      switchMap(partners => this.createRequest(partners))
    ).subscribe(
      result => {
        this.message = result ? `L'intervention a été créée. Les partenaires et le contact ont été notifiés par mail.` :
            `L'intervention n'a pas pu être créée.`;
        this.interventionCreated = true;
      }
    );
  }

  previousStep() {
    // back to previous form
    this.formDataService.resetPersonalInfo();
    this.router.navigate(['../info'], { relativeTo: this.route });
  }

  createRequest(partners: Organisation[]): Observable<boolean> {
    if (partners.length === 0) {
      this.message = `Aucun partenaire trouvé`;
      return of(false);
    } else {
      this.message = `Les partenaires suivants ont été trouvés, création de la demande d'intervention ...`;
      const partnerIds =  partners.map(p => p.id);
      this.formDataService.setPartners(partnerIds);

      // TODO : move to service?
      const request = new InterventionRequest(
        'Nouvelle intervention',
        this.formDataService.getPersonalInfo(),
        this.formDataService.getPartners(),
        this.formDataService.getSelectedJobType(),
        this.formDataService.getSelectedOptions()
      );

      return this.interventionService.createIntervention(request);
    }
  }

  ngOnDestroy(): void {
    if (this.interventionCreated$ !== undefined) {
      this.interventionCreated$.unsubscribe();
    }
  }
}
