<main class="home">
    <section style="font-family: 'BoschSansRegular',sans-serif;font-size: 14px;color: #005691;margin:5px;">
        <div class="container">
            <div class="title" style="font-size:30px;">Mentions légales</div>

            <div class="content row">
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                    <span style="font-weight: 700;"><br></span></p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                    <span style="font-weight: 700;">Editeur du Site</span><br>Le présent Site accessible à l’adresse
                    https://www.interventions.elmlecube.fr est édité par la Société elm.leblanc S.A.S, société par actions
                    simplifiées, au capital de 16 652 889€, SIREN B 542 097 944 RCS Bobigny, dont le siège social est
                    situé au 124 126 rue de Stalingrad 93711, Drancy Cedex.</p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                </p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                    Le directeur de la publication du Site est Monsieur Frédéric Agar.</p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                </p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                    <b>Hébergeur du Site<br></b><span style="color: rgb(51, 51, 51); word-spacing: normal;">Ce site est
                        hébergé sur l’offre Cloud de Microsoft (Azure). Il est géographiquement localisé dans le centre
                        d’hébergement de Microsoft en Europe occidental.<br></span><span
                        style="color: rgb(51, 51, 51); word-spacing: normal;">Pour plus d’informations, veuillez vous
                        référer à :&nbsp;</span><a
                        href="http://www.windowsazure.com/fr-fr/support/trust-center/privacy/"
                        title="Opens external link in new window" target="_blank"
                        style="background-color: rgb(255, 255, 255); word-spacing: normal; color: rgb(249, 105, 14);">http://www.windowsazure.com/fr-fr/support/trust-center/privacy/</a>
                </p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                </p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                    <span style="font-weight: 700;">Contenu du Site</span><br>Tous les éléments du présent Site internet
                    et son contenu relèvent de la compétence exclusive de la loi française et sont protégés par le droit
                    d’auteur et de la propriété intellectuelle.</p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                </p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                    Le contenu du Site, la structure générale, les logos, les textes, les images animées ou non, les
                    marques, les illustrations, les présentations etc sont la propriété exclusive de la Société
                    elm.leblanc S.A.S.</p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                </p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                    A ce titre, toute reproduction, représentation, utilisation, adaptation, modification,
                    incorporation, traduction, commercialisation, partielle ou totale de ce Site et de son contenu, d’un
                    ou plusieurs de ses composants, de ses marques, par quelques procédés que ce soient, sans
                    l’autorisation préalable expresse et écrite de la Société elm.leblanc S.A.S est interdite et est
                    susceptible de constituer une contrefaçon punie par la loi.</p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                </p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                    <span style="font-weight: 700;">Utilisation du Site</span><br>En se connectant au Site,
                    l’utilisateur accepte les présentes conditions.<br><br>Le Site est tenu à l’égard de l’utilisateur
                    d’une obligation de moyens dans l’exécution du Service.<br><br>La Société elm.leblanc S.A.S et
                    l’éditeur ne sauraient être tenus pour responsables en cas d’inaccessibilité et d’indisponibilité du
                    Site.</p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                    Le Site ne sera responsable que des seuls préjudices directs résultant d’une faute qui lui
                    imputable. Il ne peut être tenu pour responsable d’aucune perte ou manquement dans l’accomplissement
                    de ses obligations ayant pour cause la survenance d’un cas de force majeure telle que définie par
                    les tribunaux français, ou de toute autre circonstance échappant à son contrôle raisonnable
                    notamment comme l’intervention d’un tiers ayant entraîné une modification des informations du
                    Site.<br><br>Il en est de même en cas d’utilisation inappropriée par l’utilisateur.<br><br>De
                    manière générale, l’utilisateur s’engage à respecter la législation en vigueur.<br><br>A ce titre,
                    l’utilisateur est personnellement et exclusivement responsable en cas de manquement à l’une des
                    obligations mentionnées aux présentes, d’agissements frauduleux, abusifs ou illicites de sa part ou
                    s’il n’a pas satisfait intentionnellement ou par négligence grave à son obligation générale de
                    prudence.</p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                </p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                    <span style="font-weight: 700;">Recours à la Médiation pour les règlements extrajudiciaires des
                        litiges de consommation</span><br>En cas de litige, vous disposez en votre qualité de
                    consommateur et ce, conformément à l’article L612-1 et suivants du Code de la consommation, d’un
                    droit de recours auprès du Médiateur à la consommation à l’adresse email suivante :
                    contact@medicys.fr ou par voie postale :</p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                </p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                    <span style="font-weight: 700;">Service Médiation de la consommation<br>73 boulevard de
                        Clichy<br>75009 Paris</span></p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                </p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                    Tout consommateur saisissant Médicys doit pouvoir prouver, au préalable, avoir tenté de résoudre son
                    litige directement avec notre Société.</p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                </p>
                <p
                    style="font-variant-numeric: normal; font-variant-east-asian: normal; font-size: 16px; line-height: 1.375; margin-bottom: 15px;">
                    <span style="font-weight: 700;">Règlement des litiges en ligne (ODR)</span><br>La Commission
                    européenne met en place une plateforme pour le règlement extrajudiciaire des litiges. La plateforme
                    offre la possibilité de résoudre en ligne des litiges concernant les achats et les prestations de
                    services en ligne à l’adresse suivante http://ec.europa.eu/consumers/odr/.</p>
            </div>
        </div>
    </section>
</main>
