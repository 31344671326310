import { FormDataService } from 'app/interventions/services/form-data.service';
import { Component, OnInit, Input, TemplateRef, EventEmitter, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationService } from '@core/services';
import { Intervention } from '../models/intervention';
import { InterventionModalComponent } from '../intervention-modal/intervention-modal.component';
import { InterventionCauseModalComponent } from '../intervention-cause-modal/intervention-cause-modal.component';
import { InterventionUpdate } from '../models/intervention-update';
import { AvailabilityService } from '../services/availability.service';
import { Reason } from 'app/interventions/models/intervention-info';
import { Observable } from 'rxjs';
import { LoaderService } from '@shared/services/loader.service';
import { tap, filter } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-intervention-card',
  templateUrl: './intervention-card.component.html',
  styleUrls: ['./intervention-card.component.scss']
})
export class InterventionCardComponent implements OnInit {

  bsModalRef: BsModalRef;

  @Input() intervention: Intervention;
  @Output() interventionUpdated = new EventEmitter<any>();

  reasons$: Observable<Reason[]>;
  selectedReason = 0;
  confirmModalRef: BsModalRef;
  hideSelectReason: boolean = false;
  interventionIsRealized: boolean;

  // Thermostat related
  hasInstalledThermostat: string;
  typeThermostat: string;
  selectedReasonNonInstallation: string;
  listReasonNonInstallation: string[] = [
    'Refus client',
    'Installation impossible',
    'Thermostat hors-service',
    'Thermostat non compatible',
    'Thermostat non transmis au technicien',
    'Thermostat non disponible'
  ];

  // pro customer related
  usageMixte: boolean;
  majoriteHabitation: boolean;

  remplacementRequis: boolean;

  file: File = null;

  gender : boolean = true;

  constructor(private availabilityService: AvailabilityService, private modalService: BsModalService,
    private notifyService: NotificationService, private loaderService: LoaderService) { }

  ngOnInit(): void {

    // initialiaze pro customer related info
    if (this.intervention.commanditaireIntervention.typeClient == 'professionnel') {
      this.usageMixte = this.intervention.commanditaireIntervention.usageMixte;
      this.majoriteHabitation = this.intervention.commanditaireIntervention.majoriteHabitation;
    }
  }

  openDetail(intervention: Intervention) {
    const initialState = {
      intervention: intervention
    };
    this.bsModalRef = this.modalService.show(InterventionModalComponent, { initialState });
  }

  openRefusal(intervention: Intervention) {
    const initialState = {
      intervention: intervention
    };
    this.bsModalRef = this.modalService.show(InterventionCauseModalComponent, { initialState });
  }

  openConfirm(template: TemplateRef<any>, realized: boolean, hasAnomaly: boolean) {
    this.selectedReason = 0;
    this.interventionIsRealized = realized;
    this.reasons$ = this.availabilityService.getReasons(this.intervention.commanditaireIntervention.idIntervention, realized, hasAnomaly)
      .pipe(
        tap(reasons => {
          if (reasons.length === 1) {
            this.hideSelectReason = true;
            this.selectedReason = reasons[0].idMotif; // select reason if only one is available
          }
          else {
            this.hideSelectReason = false;
          }
        })
      );
    this.confirmModalRef = this.modalService.show(template);
  }

  takeInterventionCommanditaire(idIntervention) {
    const data = {
      idIntervention: idIntervention,
      accepted: true
    } as InterventionUpdate;

    this.loaderService.show();

    this.availabilityService.updateInterventionAfterAcceptanceOrRefusal(data).subscribe(() => {
      this.loaderService.hide();
      this.notifyService.showSuccess('Intervention traitée', 'Message');
      this.intervention.isPlanned = true;
    });
  }

  processCommanditaire() {
    if (this.selectedReason > 0) {
      this.availabilityService.processInterventionCommanditaire(
        this.intervention.commanditaireIntervention.idIntervention,
        this.selectedReason,
        this.hasInstalledThermostat,
        this.typeThermostat,
        this.selectedReasonNonInstallation,
        this.usageMixte,
        this.majoriteHabitation,
        this.remplacementRequis)
        .subscribe({
          next: () => {
            this.notifyService.showSuccess('Intervention mise à jour.', 'Message');
            this.intervention.isProcessed = true;
          },
          error: () => { this.notifyService.showError(`Erreur lors du traitement de l'intervention`, 'Erreur'); },
          complete: () => { this.confirmModalRef.hide(); }
        });

      if (!!this.file && this.interventionIsRealized) {
        let fd = new FormData();
        fd.append("file", this.file);
        fd.append("idIntervention", this.intervention.commanditaireIntervention.idIntervention.toString());

        this.availabilityService.uploadMaintenanceCertificate(fd).subscribe();
      }
    }
  }

  onFileChange(event) {
    this.file = event.target.files[0];
  }

  process() {
    this.availabilityService.processIntervention(this.intervention.id)
      .subscribe({
        next: () => {
          this.notifyService.showSuccess('Intervention mise à jour.', 'Message');
          this.intervention.isProcessed = true;
          this.interventionUpdated.emit();
        },
        error: () => { this.notifyService.showError(`Erreur lors du traitement de l'intervention`, 'Erreur'); },
      });
  }

  downloadMaintenanceCertificate() {
    this.availabilityService.getMaintenanceCertificate(this.intervention.id)
      .subscribe(
        (response: any) => {
          var bytes = this.base64ToArrayBuffer(response.file.toString());
          let blob: any = new Blob([bytes], { type: 'application/pdf' });
          FileSaver.saveAs(blob, 'attestation');
        },
        (error: any) => console.log('Error downloading the file')
      );
  }

  base64ToArrayBuffer(base64: any): ArrayBuffer {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
}
