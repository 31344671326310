
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobSelectorComponent } from './job-selector/job-selector.component';
import { InterventionsRoutingModule } from './interventions-routing.module';
import { InterventionComponent } from './intervention/intervention.component';
import { QuestionComponent } from './question/question.component';
import { InfoComponent } from './info/info.component';
import { PartnerComponent } from './partner/partner.component';
import { InterventionListComponent } from './intervention-list/intervention-list.component';
import { InterventionModalComponent } from './intervention-modal/intervention-modal.component';
import { InterventionHistoryComponent } from './intervention-history/intervention-history.component';
import { HomeComponent } from './home/home.component';

import { MyInterventionsComponent } from './my-interventions/my-interventions.component';
import { SharedModule } from '../shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UpdateComponent } from './update/update.component';
import { UpdateReasonComponent } from './update-reason/update-reason.component';



@NgModule({
  declarations: [JobSelectorComponent, InterventionComponent, QuestionComponent, InfoComponent,
    PartnerComponent, InterventionListComponent, InterventionModalComponent,
    InterventionHistoryComponent, HomeComponent, MyInterventionsComponent, UpdateComponent, UpdateReasonComponent],
  imports: [
    InterventionsRoutingModule,
    SharedModule


    // NgxPermissionsModule
  ],
  // entryComponents: [
  //   InterventionModalComponent
  // ]
})
export class InterventionsModule { }
