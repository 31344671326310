import { Component, OnInit, Input } from '@angular/core';
import { Intervention } from '../models/intervention';
import { Subject, InteropObservable } from 'rxjs';

@Component({
  selector: 'app-intervention-info',
  templateUrl: './intervention-info.component.html',
  styleUrls: ['./intervention-info.component.scss']
})
export class InterventionInfoComponent implements OnInit {

  @Input() intervention: Intervention;

  public onProcessed: Subject<Intervention>;

  constructor() { }

  ngOnInit() {
    this.onProcessed = new Subject();
  }

  accept() {
    this.onProcessed.next(this.intervention);
  }


}
