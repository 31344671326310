<!-- <div class="section-title">
  Mes demandes de mise en relation
</div> -->
<div class="table-responsive-md">
    <table class="table table-striped basic-table">
      <thead>
      <tr>
        <th scope="col">Type</th>
        <th scope="col">Client</th>
        <th scope="col">Adresse</th>
        <th scope="col">Proposé par</th>
        <th scope="col">Date</th>
        <th scope="col">Statut</th>
        <th scope="col"></th>
        <!-- <th scope="col">Pris en charge</th> -->
      </tr>
      </thead>
      <tbody *ngIf="!isLoading; else loading">
      <tr *ngFor="let intervention of interventions$ | async">
        <td>{{intervention.name}}</td>
        <div *ngIf="intervention.commanditaireIntervention == null then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          <td>{{intervention.contact.firstName}} {{intervention.contact.lastName}}</td>
          <td>{{intervention.contact.address}} {{intervention.contact.zip}} {{intervention.contact.city}}</td>
          <td>{{intervention.partners}}</td>
          <td>{{intervention.date | date }}</td>
          <td>Refusée</td>
        </ng-template>
        <ng-template #elseBlock>
          <td>{{intervention.commanditaireIntervention.nomClient}}</td>
          <td>{{intervention.commanditaireIntervention.adresse}} {{intervention.commanditaireIntervention.codePostal}} {{intervention.commanditaireIntervention.ville}}</td>
          <td>{{intervention.commanditaireIntervention.nomCommanditaire}}</td>
          <td>{{intervention.commanditaireIntervention.date }}</td>
          <td>{{intervention.commanditaireIntervention.statut}}</td>
        </ng-template>
        <td><button class="btn btn-light" (click)="openDetail(intervention)">Détail</button></td>
      </tr>
      </tbody>
    </table>
    </div>

    <ng-template #loading>
      <div class="text-center mt-5">
        <mat-spinner class="spinner"></mat-spinner>
      </div>
    </ng-template>