import { Component, OnInit, Input } from '@angular/core';
import { InterventionService } from '../services/intervention.service';
import { Observable } from 'rxjs';
import { Intervention } from '../models/intervention';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { InterventionModalComponent } from '../intervention-modal/intervention-modal.component';
import { NotificationService } from '@core/services';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-intervention-list',
  templateUrl: './intervention-list.component.html',
  styleUrls: ['./intervention-list.component.scss']
})
export class InterventionListComponent implements OnInit {

  title: string;
  @Input() processed: boolean;

  bsModalRef: BsModalRef;

  interventions$: Observable<Intervention[]>;

  constructor(private interventionService: InterventionService, private modalService: BsModalService,
    private notifyService: NotificationService) { }

  ngOnInit() {
    this.title = this.processed ?
      'Traitées' :
      'A traiter';

    //filter interventions based on input
    this.interventions$ = this.interventionService.myInterventions$.pipe(
      map(interventions => interventions.filter(i => i.isProcessed === this.processed))
    );
  }

  openDetail(intervention: Intervention) {
    const initialState = {
      intervention: intervention
    };
    this.bsModalRef = this.modalService.show(InterventionModalComponent, {initialState});
    this.bsModalRef.content.onProcessed.subscribe(result => {
      this.processIntervention(result);
    });
  }

  processIntervention(intervention: Intervention) {
    this.interventionService.assignIntervention(intervention.id).subscribe(() => {
      this.notifyService.showSuccess('Intervention traitée', 'Message');
    });
  }

}
