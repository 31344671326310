import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterventionHistoryComponent } from '../intervention-history/intervention-history.component';
import { InterventionAnswer, InterventionUpdateReason } from '../models/intervention-answer';
import { InterventionInfo, InterventionToConfirmInfo } from '../models/intervention-info';
import { UpdateInfo } from '../models/update-info';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  updateIntervention(info: InterventionAnswer) {
    return this.http.post<InterventionInfo>(this.baseUrl + 'api/partner/answer', info).pipe(
    );
  }

   getInterventionInfo(info: UpdateInfo): Observable<InterventionToConfirmInfo> {
    return this.http.get<InterventionToConfirmInfo>(this.baseUrl
      + `api/partner/updateInfo?guid=${info.guid}&idOrganisation=${info.idOrganisation}&realised=${info.realized}&withAnomaly=${info.hasAnomaly}`);
  }

  updateReason(info: InterventionUpdateReason) {
    console.log("voici l'info", info);
    return this.http.post<string>(this.baseUrl + 'api/partner/updateReason', info,  { responseType: 'text' as 'json'}).pipe(
    );
  }

  uploadMaintenanceCertificate(formData: FormData) {
    return this.http.post(this.baseUrl + 'api/partner/uploadMaintenanceCertificate', formData);
  }
}
