<div class="modal-header">
  <h4 class="modal-title pull-left">{{intervention.name}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="isHistory">
    <p>Créée par {{intervention.creator}} le {{intervention.date | date:'short' }}</p>
    <div class="section-subtitle">Partenaires</div>
    <ul class="list-unstyled">
      <li *ngFor="let partner of intervention.partners">{{partner}}</li>
    </ul>
  </ng-container>
  <div class="section-subtitle">Contact</div>
  <address>
    {{intervention.contact.firstName}} {{intervention.contact.lastName}}<br>
    {{intervention.contact.address}}<br>
    {{intervention.contact.address2}}<br>
    {{intervention.contact.zip}} {{intervention.contact.city}}<br>
    <abbr>Tel : </abbr>{{intervention.contact.phone}}<br>
    <a [href]="'mailto:' + intervention.contact.email">{{intervention.contact.email}}</a>
  </address>
  <p>{{intervention.contact.comments}}</p>
  <div class="section-subtitle">Informations supplémentaires</div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item" *ngFor="let question of intervention.questions">
      <em>{{question.question}}</em><br>
      {{question.answer}}
    </li>
  </ul>
</div>
<div class="modal-footer">
  <button *ngIf="!isHistory && !intervention.isProcessed" type="button" class="btn btn-primary" (click)="accept()">Traiter cette intervention</button>
  <button type="button" class="btn btn-info" (click)="bsModalRef.hide()">Fermer</button>
</div>
