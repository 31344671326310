<div class="container">
  <p>{{message}}</p>

  <div *ngIf="isAccepted">

    <h1 class="title">Récapitulatif de l'intervention</h1>

    <img [src]="'assets/images/logo_'+abreviationCommanditaire+'.svg'" class="logo" />

    <table id="{{idIntervention}}" class="table-intervention">
      <tr>
        <td class="label">Type d'intervention</td>
        <td class="data">{{typeIntervention}}</td>
      </tr>
      <tr *ngIf="hasToInstallThermostat">
        <td class="label"></td>
        <td class="data" style="color: orange; font-weight: bold;">Installation d'un thermostat</td>
      </tr>
      <tr>
        <td class="label">Commanditaire</td>
        <td class="data">{{nomCommanditaire}}</td>
      </tr>
      <tr>
        <td class="label">Date de l'intervention</td>
        <td class="data">{{date}}</td>
      </tr>
      <tr>
        <td class="label">Période</td>
        <td class="data">{{periode}}</td>
      </tr>
      <tr>
        <td class="label">Appareil</td>
        <td class="data">{{appareil}}</td>
      </tr>
      <tr>
        <td class="label">Modèle</td>
        <td class="data">{{modele}}</td>
      </tr>
      <tr>
        <td class="label">Nom Client</td>
        <td class="data">{{nomClient}}</td>
      </tr>
      <tr>
        <td class="label">Ville</td>
        <td class="data">{{ville}}</td>
      </tr>
      <tr>
        <td class="label">Adresse</td>
        <td class="data">{{adresse}}</td>
      </tr>
      <tr>
        <td class="label">Complément</td>
        <td class="data">{{complement}}</td>
      </tr>
      <tr>
        <td class="label">Étage</td>
        <td class="data">{{etage}}</td>
      </tr>
      <tr>
        <td class="label">Appartement</td>
        <td class="data">{{appartement}}</td>
      </tr>
      <tr>
        <td class="label">Code Porte</td>
        <td class="data">{{codePorte}}</td>
      </tr>
      <tr>
        <td class="label">Contact Client</td>
        <td class="data">{{contactClient}}</td>
      </tr>
      <tr>
        <td class="label">Commentaires</td>
      </tr>
      <tr>
        <td class="data">{{commentaires}}</td>
      </tr>
    </table>

  </div>
</div>
