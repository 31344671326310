import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { WorkflowService } from '../services/workflow.service';

@Injectable({
  providedIn: 'root'
})
export class WorkflowGuard implements CanActivate {
  constructor(private router: Router, private workflowService: WorkflowService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const path: string = route.routeConfig.path;
    const firstPath = this.workflowService.getFirstInvalidStep(path);
    if (firstPath.length > 0) {
        const url = `intervention/${firstPath}`;
        this.router.navigate([url]);
        return false;
    }
    return true;
  }
}
