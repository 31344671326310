import { tap } from 'rxjs/operators';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BsLocaleService, BsDatepickerInlineDirective, DatepickerDateCustomClasses, BsDatepickerInlineContainerComponent } from 'ngx-bootstrap/datepicker';
import { SelectedDate, DateType } from '../models/selected-date';
import { AvailabilityService } from '../services/availability.service';
import { ClonerService } from '@core/services/cloner.service';
import { NotificationService } from '@core/services';
import { addMonths, addYears, isThisMonth } from 'date-fns';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, AfterViewInit {
  @ViewChild(BsDatepickerInlineDirective)
  datePicker;

  currentDisplayedDate = new Date();
  selectAllChecked = false;

  dateSelected: SelectedDate[] = [];
  savedDates: SelectedDate[];
  selectedClass: DatepickerDateCustomClasses[] = [];
  disabledDates: Date[];
  minDate = new Date();

  // bsConfig: Partial<BsDatepicskerInlineDirective>;

  availabilities$: Observable<SelectedDate[]>
  isCalendarLoading = true;
  isSaving = false;

  constructor(private localeService: BsLocaleService, private availabilityService: AvailabilityService,
    private clonerService: ClonerService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.localeService.use('fr');
    this.disabledDates = this.availabilityService.getSundays();

    // this.availabilityService.selectedDates$.subscribe(
    //   {
    //     next: dates => {
    //       console.log("les dates sont", dates)
    //       this.dateSelected = dates;
    //       this.setSelectedClass();
    //     }
    //   }
    // );

    this.refreshCalendar();

    //loader
    // this.availabilityService.isLoading.subscribe(res => this.isLoading = res)
  }

  refreshCalendar() {
    this.isCalendarLoading = true;
    this.availabilities$ = this.availabilityService.getAvailabilities().pipe(
      tap(dates => {
        this.dateSelected = dates;
        this.setSelectedClass();
        this.isCalendarLoading = false;
      })
    )
  }

  ngAfterViewInit(): void {

    //obtain a ref the the BsDatepickerInlineContainerComponent (should be easier ?)
    const container = this.datePicker._datepicker.instance;

    container.navigateTo = (event: any): void => {
      container._store.dispatch(container._actions.navigateStep(event.step));

      this.selectAllChecked = false;

      if (event.step.month) {
        this.currentDisplayedDate = addMonths(this.currentDisplayedDate, event.step.month);
      } else if (event.step.year) {
        this.currentDisplayedDate = addYears(this.currentDisplayedDate, event.step.year);
      }
      //console.log(this.currentDisplayedDate);
    };
  }

  onShown(value: any) {
    console.log(value);
  }

  onValueChange(value: Date | any) {
    if (value.length === undefined) {

      this.selectAllChecked = false;

      const currentDate = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));

      const index = this.dateSelected.findIndex(item => item.date.getTime() === currentDate.getTime());

      console.log('Date', currentDate, index);

      if (index < 0) {
        this.dateSelected.push({ date: currentDate, type: DateType.AM, saved: false, hasIntervention: false });
      } else {
        const selected = this.dateSelected[index];
        selected.saved = false;
        switch (selected.type) {
          case DateType.AM:
            selected.type = DateType.PM;
            if (selected.hasIntervention) {
              this.notificationService.showWarning('Attention, une intervention est déjà planifiée sur ce jour', 'Intervention planifiée');
            }
            break;
          case DateType.PM:
            selected.type = DateType.ALL;
            break;
          case DateType.ALL:
            selected.type = DateType.NONE;
            if (selected.hasIntervention) {
              this.notificationService.showWarning('Attention, une intervention est déjà planifiée sur ce jour', 'Intervention planifiée');
            }
            // this.dateSelected.splice(index, 1);
            break;
          case DateType.NONE:
            selected.type = DateType.AM;
            break;
          default:
            break;
        }
      }
    }
    this.setSelectedClass();
  }

  saveAvailabilities() {
    this.isSaving = true
    this.availabilityService.setAvailabilities(this.dateSelected).subscribe(
      res => {
        this.isSaving = false
        this.refreshCalendar()
      })
  }

  toggleMonth(value: boolean) {
    this.selectAllChecked = value;

    if (value) {
      this.savedDates = this.clonerService.deepClone(this.dateSelected);
      this.selectMonth(this.currentDisplayedDate);
    } else {
      this.dateSelected = this.clonerService.deepClone(this.savedDates);
    }
    this.setSelectedClass();
  }

  selectMonth(date?: Date) {
    const fromToday = date && isThisMonth(date);

    const allDays = this.availabilityService.getAllDays(date, fromToday);

    allDays.forEach(day => {
      if (day.getDay() === 0) {
        return; // sunday
      }

      const index = this.dateSelected.findIndex(item => item.date.getTime() === day.getTime());
      if (index < 0) {
        this.dateSelected.push({ date: day, type: DateType.ALL, saved: false, hasIntervention: false });
      } else {
        const selected = this.dateSelected[index];
        selected.saved = false;
        selected.type = DateType.ALL;
      }
    });
  }

  private setSelectedClass() {
    if (this.dateSelected.length > 0) {
      this.selectedClass = this.dateSelected.map(date => {
        return {
          date: date.date,
          classes: [
            'custom-selected-date',
            `${date.type}`,
            date.saved ? 'saved' : 'unsaved'
          ]
        };
      });
    }
  }
}
