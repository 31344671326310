import { Component, OnInit } from '@angular/core';

import {  Observable } from 'rxjs';
import {  Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {


  public isAuthenticated: Observable<boolean>;
  constructor(private router: Router, public oidcSecurityService: OidcSecurityService) {}

  ngOnInit() {


    this.isAuthenticated = this.oidcSecurityService.isAuthenticated$.pipe(map((res => res.isAuthenticated)));
    var res = this.isAuthenticated.subscribe(data =>
    {
      if (data == true) {
        this.router.navigate(['/availability'])
      }

    })


  }
}

// interface INavigationState {
//   [ReturnUrlType]: string;
// }
