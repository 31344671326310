import { UserService } from './../../core/services/user.service';
import { tap, shareReplay, map } from 'rxjs/operators';
import { Skill } from './../models/skill';
import { AvailabilityService } from './../services/availability.service';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-update-skills',
  templateUrl: './update-skills.component.html',
  styleUrls: ['./update-skills.component.scss']
})
export class UpdateSkillsComponent implements OnInit {

  @Input() entrySkills: string[]

  skills$: Observable<Skill[]>
  isAdmin$: Observable<boolean>

  selectedSkills: number[];

  modalRef: BsModalRef;

  isSaving = false;

  deviceTypes = ["Chauffe-eau/Chauffe-bain", "Climatisation", "Chaudière gaz", "Chaudière électrique", "Pompe à chaleur", "Poêle à bois", "Poêle à granulés"];

  constructor(private modalService: BsModalService, private availabilityService: AvailabilityService, private userService: UserService) { }

  ngOnInit(): void {
    this.skills$ = this.availabilityService.getAllSkills().pipe(
      tap(skills => {
        this.selectedSkills = skills.filter(s => this.entrySkills.includes(s.name)).map(s => s.id)
      })
    );

    this.isAdmin$ = this.userService.getPermissions().pipe(
      map(perm => perm.includes('AdminDistribution')),
      shareReplay(1)
    );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  onSubmit() {
    this.isSaving = true
    this.availabilityService.updateSkills(this.selectedSkills).subscribe(res => {
      this.isSaving = false;
      this.modalRef.hide();
      this.availabilityService.skillsUpdated.emit(true);
    })
  }

  isCheckedSkill(skill: Skill) {
    return this.selectedSkills.includes(skill.id)
  }

  checkOrUncheckSkill(skill: Skill, event) {
    let isChecked = event.target.checked

    if (isChecked) {
      // add skill to selected
      this.selectedSkills.push(skill.id)
    } else {
      const index = this.selectedSkills.indexOf(skill.id);
      if (index > -1) {
        this.selectedSkills.splice(index, 1);
      }
    }
  }

  checkIfDeviceType(isAdmin: boolean, skill: Skill) {
    if (!isAdmin)
      return this.deviceTypes.includes(skill.name)

    return false;
  }
}
