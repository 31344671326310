import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, zip, } from 'rxjs';
import { AuthorizeService, PermissionService } from '@core/services';
import { tap, switchMap, map } from 'rxjs/operators';
import { ApplicationPaths, QueryParameterNames } from '../../core/api-authorization.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate {

  constructor( private router: Router,
    private permissionService: PermissionService , private oidcSecurityService:OidcSecurityService) {
  }


  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree>  {

    return zip(this.oidcSecurityService.isAuthenticated$, this.permissionService.hasPermissions$).pipe(
      map(([result, hasPerm]) => {

        if (!result.isAuthenticated) {
          this.handleAuthorization(result.isAuthenticated, state);
          return false;
        }

        return true
      })
    );
  }


  private handleAuthorization(isAuthenticated: boolean, state: RouterStateSnapshot) {
    if (!isAuthenticated) {
      this.router.navigate(ApplicationPaths.LoginPathComponents, {
        queryParams: {
          [QueryParameterNames.ReturnUrl]: state.url
        }
      });
    }else{
    }
  }
}
