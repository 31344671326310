<!-- List of PATMs (for admin view only) -->
<ng-container *ngxPermissionsOnly="['AdminDistribution']">
  <ng-container *ngIf="router.url.includes('/admin')">
    <ng-container *ngIf="patms$ | async as patms">
      <div class="form-group row m-0">
        <select id="patm" class="form-control col-lg-6 form-control-lg mb-2" [(ngModel)]="selectedPatm" (change)="patmChanged()">
          <option *ngFor="let patm of patms" [value]="patm.id">{{patm.name}} ({{patm.city}} - {{patm.postCode}})
          </option>
        </select>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ul class="nav">
  <li class="nav-item" *ngxPermissionsOnly="['RecevoirIntervention']">
    <a class="nav-link" routerLink="interventions" routerLinkActive="active">Propositions d'interventions</a>
  </li>
  <li class="nav-item" *ngxPermissionsOnly="['RecevoirIntervention']">
    <a class="nav-link" routerLink="planning" routerLinkActive="active">Mes interventions</a>
  </li>
  <li class="nav-item" *ngxPermissionsOnly="['RecevoirIntervention']">
    <a class="nav-link" routerLink="history" routerLinkActive="active">Historique</a>
  </li>
  <li class="nav-item" *ngxPermissionsOnly="['RecevoirInterventionCommanditaire']">
    <a class="nav-link" routerLink="settings" routerLinkActive="active">Mes disponibilités</a>
  </li>
  <ng-container *ngIf="!router.url.includes('/admin')">
    <li class="nav-item" *ngxPermissionsOnly="['DemandeIntervention']">
      <a class="nav-link" routerLink="/intervention/job" routerLinkActive="active">Demande de rendez-vous</a>
    </li>
  </ng-container>


  <!-- Admin view acces nav (hidden when we are inside admin view) -->
  <ng-container *ngxPermissionsOnly="['AdminDistribution']">
    <ng-container *ngIf="organizationId$ | async as organizationId">
      <li class="nav-item ml-auto" *ngIf="!router.url.includes('/admin')">
        <a class="nav-link" [routerLink]="'/admin/'+organizationId" routerLinkActive="active">
          <svg style="width: 2rem;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
            <path
              d="M156.74 124.04a24.088 24.088 0 0 0 2.439-5.83 23.838 23.838 0 0 0-2.398-18.209 23.826 23.826 0 0 0-8.809-8.78c.014-.404.028-.809.028-1.221 0-14.336-11.663-26-26-26S96 75.664 96 90c0 1.295.107 2.583.297 3.857a23.82 23.82 0 0 0-7.476 11.932c-1.642 6.13-.82 12.529 2.306 18.042L80.41 138.12l-3.68 11.044-7.711 3.856L63.529 164l6 12h20.736l24.07-40.117a23.905 23.905 0 0 0 9.662-3.101l.018-.011a24.088 24.088 0 0 0 5.774 2.408c1.211.324 2.432.541 3.655.675l7.269 14.689 7.287 7.186v7.215L153.528 176H176v-21.147l-19.26-30.813zM122 72c9.359 0 17.066 7.182 17.915 16.323-5.474-.916-11.046.085-15.913 2.895l-.002.001c-6.141-3.555-13.393-4.185-19.968-1.848C104.367 79.738 112.288 72 122 72zm-25.451 35.86a15.892 15.892 0 0 1 7.453-9.714c4.143-2.39 8.987-2.78 13.342-1.224a23.912 23.912 0 0 0-4.523 8.867A23.838 23.838 0 0 0 115.22 124c.63 1.092 1.358 2.104 2.134 3.068a15.875 15.875 0 0 1-9.494.385 15.894 15.894 0 0 1-9.713-7.453 15.888 15.888 0 0 1-1.597-12.14zM85.735 168H74.472l-2-4 2.51-5.019 8.289-4.144 4.319-12.956 8.774-11.698a23.858 23.858 0 0 0 9.122 4.899L85.736 168zm36.411-48.001a15.888 15.888 0 0 1-1.597-12.14 15.892 15.892 0 0 1 7.453-9.713 15.905 15.905 0 0 1 7.973-2.15c1.065 0 2.136.11 3.198.327-.981 3.417-2.853 6.226-5.72 8.593a4 4 0 0 0 5.094 6.168c3.722-3.073 6.347-6.827 7.873-11.208a15.995 15.995 0 0 1 3.433 4.125 15.888 15.888 0 0 1 1.598 12.14 15.892 15.892 0 0 1-7.453 9.713 15.9 15.9 0 0 1-12.139 1.598 15.894 15.894 0 0 1-9.713-7.453zM168 168h-9.528L156 163.056v-8.674l-8.713-8.592-5.228-10.565a24.122 24.122 0 0 0 5.94-2.443 24.232 24.232 0 0 0 3.315-2.33L168 157.146V168zM88.274 85.756a33.722 33.722 0 0 1 2.488-9.167 44.158 44.158 0 0 0-14.628-9.512C83.475 62.025 88.3 53.567 88.3 44c0-15.44-12.56-28-28-28s-28 12.56-28 28c0 9.472 4.732 17.855 11.952 22.925C27.75 73.274 16 89.286 16 108h8c0-19.85 16.15-36 36-36 11.459 0 21.677 5.388 28.274 13.756zM40.301 44c0-11.028 8.971-20 20-20s20 8.972 20 20-8.972 20-20 20-20-8.972-20-20z" />
          </svg>
        </a>
      </li>
    </ng-container>
  </ng-container>
</ul>
